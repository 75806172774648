import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

import auth from "@/components/General/Auth/auth";
import breadcrumbs from "./breadcrumbs.module";
import languages from "./languages.module";
import profile from "./profile.module";
import dataStructure from "./dataStructure.module";
import workflowDesigner from "./workflowDesigner.module";
import switchArea from "./switchArea.module";
import process from "./process.module";
import processDebug from "./processDebug.module";
import dataSets from "./dataSets.module";
import mapping from "./mapping.module";
import mappingTransformer from "./mappingTransformer.module";
import route from "./route.module";
import variables_v1 from "./variables_v1.module";
import variables_v2 from "./variables_v2.module";
import fileExplorer from "./fileExplorer.module";
import loadingQueue from "./loadingQueue.module";
import formHelperFactory from "./formHelperFactory.module";
import salesChannelManagementApp from "./salesChannelManagementApp.module";
import config from "./config.module";
import processTableEntries from "./processTableEntries.module";
import instance from "./instance.module";

const ls = new SecureLS({ isCompression: true });

Vue.use(Vuex);

const isLocal = salesChannelManagementApp.state.value.isLocal;
const store = new Vuex.Store({
  modules: {
    auth,
    breadcrumbs,
    languages,
    profile,
    dataStructure,
    workflowDesigner,
    switchArea,
    process,
    processDebug,
    processTableEntries,
    loadingQueue: loadingQueue,
    dataSets: dataSets,
    mapping: mapping,
    mappingTransformer: mappingTransformer,
    route: route,
    variables: variables_v1,
    variables_v2: variables_v2,
    fileExplorer: fileExplorer,
    formHelperFactory: formHelperFactory,
    salesChannelManagementApp: salesChannelManagementApp,
    config: config,
    instance: instance
  },
  plugins: [
    createPersistedState({
      paths: [
        "switchArea.selectedProject",
        "switchArea.projects",
        "switchArea.presetVersions",
        "profile.userData",
        "profile.user_personal_info",
        "process.testProcessData",
        "formHelperFactory.projects",
        "processTableEntries.processTableEntriesConfig",
        "workflowDesigner.clipboard",
        ...(isLocal
          ? [
            "salesChannelManagementApp.selectedApp",
            "salesChannelManagementApp.selectedIntegration",
            "salesChannelManagementApp.forms"
          ]
          : [])
      ],
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ]
});

export default store;
export const useStore = () => store;
