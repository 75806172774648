export const orders = {
  aggregateLineItems: "Aggregate order items per product?",
  aggregateLineItemsHelp:
    "Products ordered multiple times by Otto are reported back as individual order items with a quantity of 1. Here you can select whether the order items should be aggregated per product when creating the order in Xentral.",
  applyDiscounts: "Credits and discounts as an invoice item",
  applyDiscountsHelp:
    "Should credits and discounts be applied as a separate invoice item?",
  applyShippingCosts: "Shipping costs as an invoice item",
  applyShippingCostsHelp:
    "Should shipping costs be applied as an invoice item?",
  applySum: "Transfer the total amount of the order from the online store?",
  applySumHelp:
    "The total amount calculated in the store for the order is transferred to Xentral. Otherwise, the value calculated in Xentral applies.",
  assignDefaultCustomer: "Write each customer to default customer",
  assignExistingCustomer: "assign customer from order to existing customer",
  assignNewCustomer: "create each new customer",
  assignmentByProject: "Assign existing customers only within the project?",
  assignmentByProjectHelp:
    'If a customer is not found within the project, the procedure selected above ("If no customer is found, then") is used.',
  authorized: "guaranteed",
  blockAutoShipping: "Stop car shipping with comment?",
  blockAutoShippingHelp:
    "Should autoshipping be deactivated for orders that contain a comment?",
  bodyOutroduction: "write in free text",
  comment: "write in internal description",
  correctState: "When the correct payment and/or sanity status is reached",
  countryAssignment: "Country assignment",
  countryAssignmentHelp:
    "Here you can assign the respective countries from Xentral to a country from the online shop. This is then assigned when importing orders.",
  createCustomer: "Customer creation",
  createCustomerGuestOrder: "Customer creation guest order",
  createCustomerGuestOrderHelp:
    "How should a customer from a guest order be created in Xentral? This concerns customers who do not have a customer account in the online store.",
  createCustomerHelp:
    "How should a customer from an order be created in Xentral? This concerns customers who have a customer account in the online store.",
  createNewCustomer: "Create new customer",
  customerAssignment: "Customer assignment",
  customerAssignmentHelp:
    "Based on which characteristics should a customer from an order be assigned to an Xentral customer?",
  customerNumber: "Customer number",
  defaultCustomer: "Select default customer",
  defaultCustomerCreate: "Select default customer",
  defaultCustomerGuestOrder: "Select default customer",
  defaultCustomerHelp:
    "Which customer should be used as the default customer for store orders?",
  defaultCustomerNoCustomerFound: "Select default customer",
  defaultCustomerPos: "Select default customer (POS)",
  defaultCustomerPosHelp:
    "Which customer should be used as the default customer for POS store orders?",
  defaultDiscountProduct: "Item number for discount",
  defaultDiscountProductHelp:
    'Please select a discount article here to which credits & discounts should be posted. This only applies to discounts that apply to the entire shopping cart. Discounts on individual products are saved separately. Discount articles are created by selecting the option "Article is discount" in the article creation.',
  defaultPaymentMethod: "Default payment method",
  defaultPaymentMethodHelp:
    "The payment method from Xentral that is used by default.",
  defaultProject: "Default project",
  defaultProjectHelp: "The project from Xentral that is used by default.",
  defaultShippingMethod: "Default shipping method",
  defaultShippingMethodHelp:
    "The shipping method from Xentral that is used by default.",
  defaultShippingProduct: "Item number for shipping costs",
  defaultShippingProductHelp:
    'Please select a postage article here to which the shipping costs should be booked. Postage articles are created by selecting the option "Article is postage" in the article creation.',
  defaultSurchargeProduct: "Article number for surcharges",
  defaultSurchargeProductHelp:
    "Please select an article for surcharges to which the surcharges are to be posted. All surcharges will be totalled.",
  deliveryCondition: "Delivery conditions",
  deliveryConditions:
    "Which delivery conditions should apply to the order import for this online store?",
  deliveryStateAll: "All",
  deliveryStateDelivered: "Completed",
  deliveryStateForOrders: "Which delivery state should be used as the trigger?",
  deliveryStateForOrdersHelp:
    "Which delivery state should be used as a trigger to retrieve orders?",
  deliveryStateOpen: "Open",
  deliveryStateProcessing: "In processing",
  deliveryTerms:
    "Which delivery terms should apply to the order import for this online store?",
  deliveryTermsHelp:
    "The desired delivery term can be selected here using the search function. If no delivery term exists yet, one must first be created in Xentral.",
  discounts: "Credit notes & discounts",
  discountsNumber: "Credits and discounts with multiple tax rates",
  discountsNumberHelp:
    "Should additional tax rates on credit notes and discounts be taken into account?",
  discountsTaxesAssignment: "Tax rate > postage article assignment",
  discountsTaxesAssignmentHelp:
    "If, in addition to the normal tax rate, other tax rates are to be taken into account on credit notes and discounts, the corresponding postage items for the various tax rates can be specified here.  To do this, a discount article must be created for each tax rate to be taken into account and selected here.",
  discountsTaxesAssignmentSelect: "Other tax rates",
  discountsTaxesAssignmentSelectHelp:
    "Should additional tax rates on credits and discounts be taken into account?",
  fastLaneMethodIds: "Fast lane shipping methods",
  fastLaneMethodIdsHelp:
    "Here you can select Xentral shipping methods for which the fast lane should be activated.",
  granted: "Payment guaranteed",
  mail: "Mail address",
  maxDifference:
    "Maximum difference to the shopping cart total calculated in Xentral",
  maxDifferenceHelp:
    "If this difference is exceeded, the total amount of the order calculated in Xentral will be used.",
  nameAddress: "Name & address",
  noCustomerFound: "If no customer is found, then",
  noCustomerFoundHelp:
    "If no customer was found according to the specified search characteristics, what should be done next?",
  noImport: "Do not import order",
  noOrderRemark: "do not observe",
  orderInput: "When the order is received",
  orderRemark: "Process comment from order?",
  orderRemarkHelp:
    "Should the comment from the store order be processed in Xentral? If the field is left empty, the comment is ignored.",
  paid: "paid",
  partiallyPaid: "partially paid",
  paymentMethodsAssignment: "Payment method assignment",
  paymentMethodsAssignmentHelp:
    "Payment methods from Xentral can be assigned to the corresponding payment methods of the online store here",
  paymentMethodsAssignmentSelect: "Payment method assignment strategy",
  paymentMethodsAssignmentSelectHelp:
    "How should payment methods from the order be assigned?",
  paymentMethodsAssignmentXentral:
    "Transfer payment methods from the online store",
  paymentOpen: "Payment open",
  paymentStateForOrders: "Payment status to retrieve orders",
  paymentStateForOrdersHelp:
    "Which payment status should be used as a trigger to retrieve orders?",
  pending: "open",
  posCustomers: "Are there POS customers?",
  posCustomersHelp:
    "If this integration contains POS (Point of Sale) customers, these customers are always written to a default POS customer. This must be selected below.",
  projectHelp:
    "Here you can select the Xentral project that will be assigned to orders during import.",
  salesChannelAssignment: "Sales channel <> Project assignment",
  salesChannelAssignmentHelp:
    "Here you can assign the respective sales channels from {appName} to an Xentral project. This is then assigned when orders are imported.",
  scopeProject: "Assign existing customers only within the project?",
  scopeProjectHelp:
    'If a customer is not found within the project, the procedure defined in "Customer creation" is used',
  shippingCosts: "Shipping costs",
  shippingCostsNumber: "Shipping costs with multiple tax rates",
  shippingCostsNumberHelp:
    "Should additional tax rates on shipping costs be taken into account?",
  shippingCostsTaxesAssignment: "Tax rate > postage item assignment",
  shippingCostsTaxesAssignmentHelp:
    "If, in addition to the normal tax rate, other tax rates are to be taken into account on shipping costs, the corresponding postage items for the various tax rates can be specified here.  To do this, a postage article must be created for each tax rate to be taken into account and selected here.",
  shippingCostsTaxesAssignmentSelect: "Other tax rates",
  shippingCostsTaxesAssignmentSelectHelp:
    "Should further tax rates on shipping costs be taken into account?",
  shippingMethodsAssignment: "Shipping methods assignment",
  shippingMethodsAssignmentHelp:
    "Shipping methods from Xentral can be assigned to the corresponding shipping methods of the online store here",
  shippingMethodsAssignmentSelect: "Strategy shipping method assignment",
  shippingMethodsAssignmentSelectHelp:
    "How should the assignment of shipping methods from the order take place?",
  shippingMethodsAssignmentXentral:
    "Adopt shipping methods from the online store",
  tag: "Tag",
  tagAssignmentTableId: "Tag <> Project assignment",
  tagAssignmentTableIdHelp:
    "Here you can assign the respective tags of orders from Shopify to an Xentral project. This is then assigned when importing orders. The project assignment based on the tag overwrites the project assignment based on the sales channel.",
  taxCalculationStrategy: "How should taxes be calculated in Xentral? ",
  taxCalculationStrategyHelp:
    "Taxes are calculated per item and then added up. Otherwise, the tax is calculated on the shopping cart total.",
  taxEmptyToExemptedTax:
    "Transfer exempt tax rate if tax information is missing",
  taxEmptyToExemptedTaxHelp:
    "Here you can select whether items with missing tax information should be transferred from Shopify with an exempt tax rate.",
  taxes: "Taxes",
  updateStrategyPayment: "Payment status",
  updateStrategyPaymentHelp:
    "Which payment status or statuses should be used as triggers to retrieve orders?",
  updateStrategy: "Order status",
  updateStrategyHelp:
    "Which order status or statuses should be used as triggers to retrieve orders?"
};
