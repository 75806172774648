export const productSelection = {
  category: "Kategorie",
  invalidSelection:
    "Es gibt einen Fehler bei der Konfiguration der Artikelzuordnung",
  linkProducts:
    "In beiden Systemen befinden sich schon Artikel > Artikel verknüpfen",
  productSelection: "Artikelzuordnung",
  productSelectionMissing:
    "Diese Integration kann erst verwendet werden, wenn die Artikelzuordnung konfiguriert wurde.",
  productsToShop:
    "Es befinden sich bereits Artikel in Xentral, aber noch nicht im Shop > Daten aus Xentral zum Shop übertragen",
  project: "Projekt",
  saveDescription:
    "Bitte speichere die Artikelzuordnung nur, wenn sich tatsächlich etwas an dem Filter geändert hat. " +
    'Wenn du die Artikelzuordnung speicherst, werden erneut alle ausgewählten Artikel exportiert, was je nach Anzahl der Artikel etwas Zeit in Anspruch nehmen kann. Wenn du nur das Ergebnis des Filters sehen willst, kannst du den "Aktualisieren" Button über der Tabelle in der Artikelzuordnung nutzen.',
  saveTitle: "Artikelzuordnung speichern?",
  strategy: "Welche Artikel möchtest du übertragen?",
  strategyHelp:
    "Welche Artikel möchtest du zwischen Xentral und deinem Shop synchronisieren? Diese Auswahl gilt für alle Workflows.",
  title: "Artikelzuordnung",
  value: "Wie sollen wir Xentral mit deinem Shop verbinden?",
  valueHelp:
    "Um deinen Shop mit Xentral zu verbinden müssen wir die Artikel zwischen beiden Systemen verknüpfen. Bitte gib an, welche Situation auf dich zutrifft."
};
