export const product = {
  catalog: "catalog",
  categories: "Categories",
  categoryAssignmentHelp:
    "Shopify has a fixed category system. Here you can assign your Xentral categories to the Shopify categories.",
  categoryAssignmentSelect:
    "How should the items from Xentral be assigned to the categories in the online store?",
  categoryAssignmentSelectXentral: "Transfer article tree from Xentral",
  correctState: "When the correct payment and/or shipping status is reached",
  country: "Country of the company headquarters",
  countryHelp: "In which country is your company registered?",
  crossSeller: "Cross-selling item",
  customFieldAssignmentHelp:
    "Free fields from Xentral can be assigned to the corresponding free fields of the online store here",
  customFieldAssignmentStrategy:
    "How should the free fields from Xentral be transferred to the online store?",
  customFieldAssignmentStrategyXentral: "Transfer free fields from Xentral",
  customFieldAssignmentStrategyXentralCustomProperties:
    "Manually assign free fields from Xentral to the properties available in the shop",
  customFieldAssignmentStrategyXentralProperties:
    "Automatically write free fields from Xentral to available properties",
  customFieldPropertiesAssignmentHelp:
    "Free fields from Xentral can be assigned to the corresponding properties of the online shop here",
  deleteProducts: "Automatically delete products in the shop?",
  deleteProductsHelp:
    "Here you can activate that products that you delete in Xentral are also deleted in the shop. If a matrix article is deleted, all associated variants are deleted in the shop.",
  exemptedTaxCollectionId: "Collection for exempted tax rate",
  exemptedTaxCollectionIdHelp:
    "In Shopify, items are assigned to an exempt tax rate via collections. This collection must be created in the shop and selected here.",
  images: "Images",
  importNettoPrices: "Transfer net prices",
  importNettoPricesHelp:
    "If you want to transfer net prices instead of gross prices to the store, you can select that here.",
  layout: "Selection of the product layout",
  layoutHelp:
    "Here you can select which layout should be used for the product page in the shop. If no view is selected, the default layout of the shop is used.",
  mediaFolder: "Selection of media folder",
  mediaFolderHelp:
    "Here you can select in which folder the article images are stored in the shop.",
  mediaUpdateStrategy: "Image update strategy",
  mediaUpdateStrategyCreate: "I only want to create images",
  mediaUpdateStrategyCreateAndDelete:
    "I would like to create and delete images",
  mediaUpdateStrategyHelp:
    "Here you can decide how to proceed with the transfer of images.",
  metaTextsActive: "Transfer meta title and description?",
  metaTextsActiveHelp:
    "Here you can specify whether the meta or online shop texts (title and description) should be transferred to the shop. These are written in the meta text or SEO fields provided for this purpose.",
  metaTextsKeywordsActive: "Transfer meta title, description and keywords?",
  metaTextsKeywordsActiveHelp:
    "Here you can specify whether the meta or online shop texts (title, description and keywords) should be transferred to the shop. These are written in the meta text or SEO fields provided for this purpose.",
  newUpdate: "Create new / update articles",
  newUpdateHelp:
    "Should items be newly created if not yet in the store and/or updated if already in the store?",
  newUpdateNew: "Only create new",
  newUpdateNewUpdate: "Create new and update",
  newUpdateUpdate: "Update only",
  orderInput: "On receipt of order",
  productAssignmentHelp:
    "Which items should be imported from Xentral into the store?",
  productAssignmentSelect:
    "How should the articles from Xentral be assigned to the articles in the online store?",
  productAssignmentSelectXentral: "Transfer article numbers from Xentral",
  propertyAssignmentStrategyXentralCustomFields:
    "Automatically write properties from Xentral to available custom fields",
  propertyAssignmentStrategyXentralCustomCustomFields:
    "Manually assign properties from Xentral to the custom fields available in the shop",
  propertyCustomFieldsAssignmentHelp:
    "Properties from Xentral can be assigned to the corresponding custom fields of the online shop here",
  properties: "Properties",
  propertiesVisible: "Make properties visible?",
  propertiesVisibleHelp:
    'Here you can specify whether transferred properties should be visible on the "Additional information" tab on the product page.',
  reducedTaxRateCollectionId: "Collection for reduced tax rate",
  reducedTaxRateCollectionIdHelp:
    "In Shopify, items are assigned to a reduced tax rate via collections. This collection must be created in the store and selected here.",
  salesChannel: "Sales channels",
  salesChannelHelp:
    "A sales channel must be created in {appName} in which you want to sell your products. You must select this here.",
  selectProduct: "Select article",
  selectProductHelp: "Which products should be transferred?",
  stockLessZeroBuyable: "Should items with stock < 0 be buyable in the store?",
  stockLessZeroShown: "Should items with stock < 0 be displayed in the store?",
  stockLessZeroTransferred: "Should items with stock < 0 be transferred?",
  tags: "Tags",
  taxClass: "Tax class",
  taxRateFree: "Tax rate (exempt)",
  taxRateFreeHelp:
    "This tax rate is used to transfer the prices for tax-exempt items from the shop to Xentral.",
  taxRateNormal: "Tax rate (normal)",
  taxRateNormalHelp:
    "This tax rate is used to convert the prices of normally taxed items from the shop to gross and transfer them to Xentral.",
  taxRateReduced: "Tax rate (reduced)",
  taxRateReducedHelp:
    "This tax rate is used to convert the prices of reduced taxed items from the shop to gross and transfer them to Xentral.",
  taxes: "Taxes",
  updateStrategy: "Update strategy",
  updateStrategyCreate: "I only want to create new items",
  updateStrategyCreateAndUpdate:
    "I would like to update existing articles and create new articles automatically",
  updateStrategyHelp:
    "Here you can decide how you want to transfer items from Xentral to your store. Note: If you want to create items, at least master data must be transferred.",
  updateStrategyUpdate: "I only want to update existing items",
  variants: "Variants (incl. prices and stock)",
  useNetWeight: "Transfer net weight?",
  useNetWeightHelp:
    "Here you can specify whether the weight or net weight should be transferred to the shop.",
  warehouseCountry: "Country of the Xentral warehouse",
  warehouseCountryHelp: "In which country is your warehouse?",
  initialImportFileBuilding: "The initial import file is created",
  articleExported: "Article exported",
  initialImportFileBuildingInfo:
    "As soon as the initial import file has been created, you can download it via the link."
};
