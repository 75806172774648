export const prices = {
  advancedPricingActive: "Erweiterte Preise importieren",
  advancedPricingActiveHelp:
    "Hier werden alle Verkaufspreise mit Kundengruppen, Staffelpreise, verschiedene Währungen und deren Gültigkeit importiert, sofern die Kundengruppen und Währungen zugeordnet wurden.",
  currencyAssignmentHelp:
    "Hier können die Währungen aus Xentral den entsprechenden Währungen in {appName} zugeordnet werden.",
  customerGroupAssignmentStrategy:
    "Wie sollen die erweiterten Preise zugeordnet werden?",
  customerGroupAssignmentStrategyCustomerGroup:
    "Kundengruppen aus Xentral den Kundengruppen im Shop zuordnen",
  customerGroupAssignmentStrategyPriceRule:
    "Kundengruppen aus Xentral den Preisregeln im Shop zuordnen",
  customerGroupAssignmentTableId: "Kundengruppenzuordnung",
  customerGroupAssignmentTableIdHelp:
    "Hier können die Kundengruppen aus Xentral den entsprechenden Kundengruppen in {appName} zugeordnet werden",
  priceGroup: "Kunde bzw. Kundengruppe",
  priceGroupHelp:
    "Hier kannst du angeben, welcher Preis von Xentral in den Shop übertragen werden soll. Dafür gib hier bitte den Namen des Kunden bzw. der Kundengruppe ein. Wenn du dieses Feld leer lässt, wird der Standardpreis verwendet.",
  priceRulesAssignmentTableId: "Zuordnung der Preisregeln",
  priceRulesAssignmentTableIdHelp:
    "Hier können die Kundengruppen aus Xentral den entsprechenden Preisregeln in {appName} zugeordnet werden",
  roundPrices: "Preise vor der Übertragung in den Shop runden?",
  roundPricesHelp:
    "Hier kannst du auswählen, ob Preise vor der Übertragung in den Shop gerundet werden sollen. Dies kann mögliche Rundungsdifferenzen eliminieren.",
  transferGraduatedPrices: "Staffelpreise übertragen",
  transferNetPrice: "Preise als netto übertragen"
};
