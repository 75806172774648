export const product = {
  catalogue: "Katalog",
  categories: "Kategorien",
  categoryAssignmentSelectXentral: "Artikelbaum aus Xentral übernehmen",
  correctState: "Bei Erreichen des richtigen Zahlungs-/ und oder Versandstatus",
  country: "Land des Firmensitzes",
  countryHelp: "In welchem Land ist deine Firma eingetragen ?",
  crossSeller: "Cross-selling Artikel",
  customFieldAssignmentHelp:
    "Hier können Freifelder aus Xentral den entsprechenden Freifeldern des Onlineshops zugeordnet werden",
  customFieldAssignmentStrategy:
    "Wie sollen die Freifelder aus Xentral an den Onlineshop übergeben werden?",
  customFieldAssignmentStrategyXentral: "Freifelder aus Xentral übernehmen",
  customFieldAssignmentStrategyXentralCustomProperties:
    "Freifelder aus Xentral den im Shop verfügbaren Eigenschaften manuell zuordnen",
  customFieldAssignmentStrategyXentralProperties:
    "Freifelder aus Xentral automatisch in verfügbare Eigenschaften schreiben",
  customFieldPropertiesAssignmentHelp:
    "Hier können Freifelder aus Xentral den entsprechenden Eigenschaften des Onlineshops zugeordnet werden",
  deleteProducts: "Produkte automatisch in Shop löschen?",
  deleteProductsHelp:
    "Hier kannst du aktivieren, dass Produkte, die du in Xentral löschst, auch im Shop gelöscht werden. Wenn ein Matrixartikel gelöscht wird, werden alle zugehörigen Varianten im Shop gelöscht.",
  exemptedTaxCollectionId: "Collection für befreiten Steuersatz",
  exemptedTaxCollectionIdHelp:
    "In Shopify werden Artikel über Collections einem befreiten Steuersatz zugewiesen. Diese Collection muss im Shop angelegt und hier ausgewählt werden.",
  images: "Bilder",
  importNettoPrices: "Netto Preise übertragen",
  importNettoPricesHelp:
    "Wenn du Nettopreise statt Bruttopreise in den Shop übertragen möchtest, kannst das hier auswählen.",
  layout: "Auswahl der Artikelansicht",
  layoutHelp:
    "Hier kann ausgewählt werden, welche Ansicht für die Artikelseite im Shop verwendet werden soll. Wird keine Ansicht ausgewählt, wird die Standardansicht des Shops verwendet.",
  mediaFolder: "Auswahl Medienordner",
  mediaFolderHelp:
    "Hier kannst du auswählen, in welchem Ordner die Artikelbilder im Shop abgelegt werden.",
  mediaUpdateStrategy: "Bilder Update Strategie",
  mediaUpdateStrategyCreate: "Ich möchte Bilder nur anlegen",
  mediaUpdateStrategyCreateAndDelete: "Ich möchte Bilder anlegen und löschen",
  mediaUpdateStrategyHelp:
    "Hier kannst du entscheiden, wie bei der Übertragung der Bilder vorgegangen werden soll.",
  metaTextsActive: "Meta Titel und Beschreibung übertragen?",
  metaTextsActiveHelp:
    "Hier kannst du festlegen, ob die Meta bzw. Online-Shop Texte (Titel und Beschreibung) zum Shop übertragen werden sollen. Diese werden in dafür vorgesehene Meta-Text bzw. SEO-Felder geschrieben.",
  metaTextsKeywordsActive: "Meta Titel, Beschreibung und Keywords übertragen?",
  metaTextsKeywordsActiveHelp:
    "Hier kannst du festlegen, ob die Meta bzw. Online-Shop Texte (Titel, Beschreibung und Keywords) zum Shop übertragen werden sollen. Diese werden in dafür vorgesehene Meta-Text bzw. SEO-Felder geschrieben.",
  newUpdate: "Neu anlegen / updaten von Artikeln",
  newUpdateHelp:
    "Sollen Artikel neu angelegt werden, wenn noch nicht im Shop vorhanden und/ oder geupdated werden, wenn bereits im Shop vorhanden?",
  newUpdateNew: "Nur neu anlegen",
  newUpdateNewUpdate: "Neu anlegen und updaten",
  newUpdateUpdate: "Nur updaten",
  orderInput: "Bei Auftragseingang",
  productAssignmentHelp:
    "Welche Artikel sollen von Xentral in den Shop importiert werden?",
  productAssignmentSelect:
    "Wie sollen die Artikel aus Xentral den Artikeln im Onlineshop zugeordnet werden?",
  productAssignmentSelectXentral: "Artikelnummern aus Xentral übernehmen",
  propertyAssignmentStrategyXentralCustomFields:
    "Eigenschaften aus Xentral automatisch in verfügbare Freifelder schreiben",
  propertyAssignmentStrategyXentralCustomCustomFields:
    "Eigenschaften aus Xentral den im Shop verfügbaren Freifeldern manuell zuordnen",
  properties: "Eigenschaften",
  propertyCustomFieldsAssignmentHelp:
    "Hier können Eigenschaften aus Xentral den entsprechenden Freifeldern des Onlineshops zugeordnet werden",
  propertiesVisible: "Eigenschaften sichtbar machen?",
  propertiesVisibleHelp:
    'Hier kannst du festlegen, ob übertragene Eigenschaften auf der Registerkarte "Zusätzliche Informationen" auf der Produktseite sichtbar sein sollen.',
  reducedTaxClassId: "Steuerklasse für reduzierte Steuersätze",
  reducedTaxClassIdHelp: "Steuerklasse für reduzierte Steuersätze",
  reducedTaxRateCollectionId: "Collection für reduzierten Steuersatz",
  reducedTaxRateCollectionIdHelp:
    "In Shopify werden Artikel über Collections einem reduzierten Steuersatz zugewiesen. Diese Collection muss im Shop angelegt und hier ausgewählt werden.",
  salesChannel: "Sales Channels",
  salesChannelHelp:
    "In {appName} muss ein Sales Channel angelegt werden, in dem du deine Artikel verkaufen willst. Diesen musst du hier auswählen.",
  selectProduct: "Auswahl Artikel",
  selectProductHelp: "Welche Artikel sollen übertragen werden?",
  stockLessZeroBuyable: "Sollen Artikel mit Bestand < 0 im Shop kaufbar sein?",
  stockLessZeroShown:
    "Sollen Artikel mit Bestand < 0 im Shop angezeigt werden?",
  stockLessZeroTransferred: "Sollen Artikel mit Bestand < 0 übertragen werden?",
  tags: "Tags",
  taxClass: "Steuerklasse",
  taxRateFree: "Steuersatz (befreit)",
  taxRateFreeHelp:
    "Dieser Steuersatz wird verwendet, um die Preise für steuerbefreite Artikel vom Shop zu Xentral zu übertragen.",
  taxRateNormal: "Steuersatz (normal)",
  taxRateNormalHelp:
    "Dieser Steuersatz wird verwendet, um die Preise von normal besteuerten Artikeln aus dem Shop in Brutto umzurechnen und in Xentral zu übertragen.",
  taxRateReduced: "Steuersatz (ermäßigt)",
  taxRateReducedHelp:
    "Dieser Steuersatz wird verwendet, um die Preise von reduziert besteuerten Artikeln aus dem Shop in Brutto umzurechnen und in Xentral zu übertragen.",
  taxes: "Steuern",
  updateStrategy: "Update Strategie",
  updateStrategyCreate: "Ich möchte nur neue Artikel anlegen",
  updateStrategyCreateAndUpdate:
    "Ich möchte bestehende Artikel updaten und neue Artikel automatisch anlegen",
  updateStrategyHelp:
    "Hier kannst du entscheiden, wie du Artikel von Xentral zu deinem Shop übertragen willst. Hinweis: Wenn du Artikel anlegen möchtest, müssen mindestens Stammdaten übertragen werden.",
  updateStrategyUpdate: "Ich möchte nur bestehende Artikel updaten",
  variants: "Varianten (exkl. Preise und Bestände)",
  useNetWeight: "Nettogewicht übertragen?",
  useNetWeightHelp:
    "Hier kannst du festlegen, ob das Gewicht oder das Nettogewicht zum Shop übertragen werden soll.",
  warehouseCountry: "Standort des Xentral Lagers",
  warehouseCountryHelp: "In welchem Land ist dein Xentral Lagerhaus?",
  articleExported: "Artikel exportiert"
};
