import Projects from "@/components/Settings/Projects/project";
import PresetVersions from "@/layout/presetVersions";
import ApiService from "@/core/services/api.service";

// action types
export const UPDATE_SELECTED_PROJECT = "updateSelectedProject";
export const UPDATE_SELECTED_PRESET_VERSION = "updateSelectedPresetVersion";

export const LOAD_PROJECTS = "loadProjectsSwitch";

export const LOAD_PRESET_VERSIONS = "loadPresetVersions";

// mutation types
export const SET_PROJECTS = "setProjects";
export const SET_PRESET_VERSIONS = "setPresetVersions";

export const RESET_PROJECTS = "resetProjects";

export const SET_SELECTED_PROJECT = "setSelectedProject";

export const SET_SELECTED_PRESET_VERSION = "setSelectedPresetVersion";

export default {
  state: {
    projects: [],
    presetVersions: [],

    selectedProject: null,
    selectedPresetVersion: null
  },
  getters: {
    projects(state) {
      return state.projects;
    },
    selectedProject(state) {
      return state.selectedProject;
    },
    project: state => id => {
      return state.projects.find(project => project.id === id);
    },
    isPresetProject(state, getters) {
      return !!getters.selectedProject?.preset_id;
    },
    presetVersions(state) {
      return state.presetVersions;
    },
    selectedPresetVersion(state) {
      return state.selectedPresetVersion;
    },
    isDevPresetVersion(state) {
      return (
        !state.selectedPresetVersion ||
        state.selectedPresetVersion?.is_dev_version
      );
    }
  },
  actions: {
    [UPDATE_SELECTED_PROJECT](context, payload) {
      context.commit(SET_SELECTED_PROJECT, payload);
    },

    [UPDATE_SELECTED_PRESET_VERSION](context, payload) {
      context.commit(SET_SELECTED_PRESET_VERSION, payload);
    },

    [RESET_PROJECTS](context) {
      context.commit(RESET_PROJECTS);
    },

    [LOAD_PROJECTS](context, additionalFilter = []) {
      const params = {
        noPagination: true,
        filter: [
          ...additionalFilter,
          {
            key: "active",
            op: "equals",
            value: true
          }
        ]
      };

      context.dispatch("loadingQueue/addEventToLoadingQueue", {
        key: "loadProjects"
      });

      Projects.getAll(params)
        .then(response => {
          context.commit(SET_PROJECTS, response.data);

          let project = response.data[0] ?? null;

          // After loading the projects, it is possible that the currently selected project has been renamed or deleted. In this case, the currently selected project must be updated.
          const selectedProject = response.data.find(
            p => p.id === this.getters.selectedProject?.id
          );

          context.commit(SET_SELECTED_PROJECT, selectedProject ?? project);
        })
        .finally(() => {
          context.dispatch("loadingQueue/removeEventFromLoadingQueue", {
            key: "loadProjects"
          });
        });
    },
    [LOAD_PRESET_VERSIONS](context) {
      const params = {
        noPagination: true
      };
      context.dispatch("loadingQueue/addEventToLoadingQueue", {
        key: "loadPresetVersions"
      });
      PresetVersions.getNonArchived(params)
        .then(response => {
          context.commit(SET_PRESET_VERSIONS, response.data);
        })
        .finally(() => {
          context.dispatch("loadingQueue/removeEventFromLoadingQueue", {
            key: "loadPresetVersions"
          });
        });
    }
  },
  mutations: {
    [SET_PROJECTS](state, payload) {
      state.projects = payload;
    },

    [RESET_PROJECTS](state) {
      state.projects = [];
    },

    [SET_PRESET_VERSIONS](state, versions) {
      state.presetVersions = versions;
    },

    [SET_SELECTED_PROJECT](state, payload) {
      if (payload) {
        payload.shortName = payload.short;
        ApiService.setHeaderByKey("x-project-id", payload.id);
      }

      state.selectedProject = payload;
    },

    [SET_SELECTED_PRESET_VERSION](state, payload) {
      if (payload && payload.is_dev_version) {
        ApiService.removeHeaderByKey("x-preset-version");
      } else if (payload && !payload.is_dev_version) {
        ApiService.setHeaderByKey("x-preset-version", payload.id);
      }

      state.selectedPresetVersion = payload;
    }
  }
};
