export const settings = {
  barcode: "Barcode",
  customsTariffNumber: "customs tariff number",
  ean: "EAN no./barcode",
  name: "Name",
  productAssignmentLabel:
    "How should the items from Xentral be assigned to the items in the online store?",
  productAssignmentSelectXentral: "Adopt article numbers from Xentral",
  productAssignmentSource: "Field from Xentral",
  productAssignmentTarget: "Field from {appName}",
  productnumber: "Item number",
  sku: "SKU",
  title: "Title",
  productSelection: "Product selection"
};
