export const category = {
  categoryAssignment: "Kategorie <> Shopify Produkt Taxonomie Zuordnung",
  categoryAssignmentBigcommerceHelp:
    "Hier kannst du deine Xentral Kategorien den BigCommerce Kategorien zuordnen.",
  categoryAssignmentHelp:
    'Hier kannst du deine Xentral Kategorien (Artikelbaum) den Shopify Standard Kategorien zuordnen. Beachte, dass an dieser Stelle die Standardkategorien aus der Shopify Produkt Taxonomie gefüllt werden. Collections kannst du füllen, indem du die Übertragung von Tags im Reiter "Artikel" aktivierst.',
  categoryAssignmentSelect:
    "Wie sollen die Artikel aus Xentral den Kategorien im Onlineshop zugeordnet werden?",
  mainCategoryId: "Einstiegspunkt für den Artikelbaum",
  mainCategoryIdHelp:
    "Hier kannst du auswählen, welche Kategorie als Einstiegspunkt für deinen Artikelbaum verwendet werden soll.",
  merchandiseGroupToProductType: "Warengruppe in Produkttyp übertragen?",
  merchandiseGroupToProductTypeHelp:
    "Hier kannst du festlegen, ob du die Xentral Warengruppe (Artikelkategorie) in den Shopify Produkttyp übertragen möchtest.",
  placeholder: "Kategorie"
};
