export const productSelection = {
  category: "Category",
  invalidSelection:
    "There is an error in the configuration of the product selection",
  linkProducts: "There are already articles in both systems > Link articles",
  productSelection: "Product selection",
  productSelectionMissing:
    "This integration can only be used once the product selection has been configured.",
  productsToShop:
    "There are already products in Xentral, but not yet in the shop > Transfer data from Xentral to the shop",
  project: "Project",
  saveDescription:
    "Please only save the article assignment if something has actually changed in the filter. " +
    'When you save the item assignment, all selected items are exported again, which can take some time depending on the number of items. If you only want to see the result of the filter, you can use the "Refresh" button above the table in the item assignment',
  saveTitle: "Save article assignment?",
  strategy: "Which products do you want to transfer?",
  strategyHelp:
    "Which items do you want to synchronise between Xentral and your shop? This selection applies to all workflows.",
  title: "Product selection",
  value: "How should we connect Xentral with your shop?",
  valueHelp:
    "To connect your shop with Xentral, we need to link the items between both systems. Please specify which situation applies to you."
};
