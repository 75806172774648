export const tracking = {
  cancelOrders: "Cancel orders automatically in the shop?",
  cancelOrdersHelp:
    "Here you can select whether orders that are cancelled in Xentral should also be automatically cancelled in the shop.",
  carrierAssignmentHelp:
    "Shipping methods from Xentral can be assigned to the corresponding carriers of the online shop here.",
  city: "City",
  completed:
    "Update order status of all items as soon as the order is completed.",
  countryCode: "Country (ISO 3166-1 alpha-3 code)",
  notifyByMail: "Activate e-mail notification",
  notifyByMailHelp:
    "Here you can select whether {appName} should send an email to the customer when the delivery has been dispatched.",
  returns: "Report returns",
  returnsHelp:
    "Here you can select whether returns that are created in Xentral should be automatically transferred to the shop.",
  shipFromAddress:
    "The address specified here is used as the warehouse address when reporting order status and tracking to Otto.",
  trackingAvailable:
    "Update order status per item as soon as tracking information is available.",
  updateStrategyOrderStatus: "Order status for order status & tracking export",
  updateStrategyOrderStatusHelp:
    "When should the order status be reported back?",
  zipCode: "Postcode (postcode)",
  provisionalReturnNumbers: "Return numbers",
  provisionalReturnNumbersInfo:
    "Enter your return numbers here, separated by commas. The numbers from this pool will then be used for the dispatch confirmation."
};
