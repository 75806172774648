export const migration = {
  activeFeatures: "Aktive Features",
  category: "Standardkategorie",
  categoryHelp:
    "Die Kategorie, in die standardmäßig die Artikel geschrieben werden.",
  changeFinishMigrationStatus:
    "Der Status der Migration wurde erfolgreich geändert.",
  changeMigrationActiveStatusTextActive:
    "Achtung! Wenn du den initialer Datenimport deaktivierst, wird der Produktivmodus automatisch aktiviert. Bitte stelle sicher, dass du die Migration beendet hast, da es sonst zu Fehlern kommen kann. Diese Änderung greift erst beim nächsten Speichern.",
  changeMigrationActiveStatusTextInactive:
    "Achtung! Wenn du den initialer Datenimport aktivierst, wird der Produktivmodus automatisch deaktiviert. Bitte stelle sicher, dass keine Workflows mehr im Produktivmodus laufen, da es sonst zu Fehlern kommen kann. Diese Änderung greift erst beim nächsten Speichern.",
  changeMigrationActiveStatusTitle: "Modus der Integration ändern",
  changeMigrationTitle: "Initialer Datenimport",
  createFirstIntegrationTitle: "Keine Integrationen gefunden",
  createFirstIntegrationDescription:
    "Lege eine Integration an um fortzufahren. Du hast die Wahl eine bereits bestehende Integration zu Connect zu migrieren, sofern diese existieren oder eine neue Integration anzulegen.",
  currency: "Währung",
  currencyHelp: "Bitte gib hier die Währung deiner Preise an.",
  development: "Entwicklung",
  ean: "EAN Nr. / Barcode",
  failed: "Es müssen alle Pflichtfelder ausgefüllt werden.",
  finish: "Migration beenden",
  finishDescription: "Möchten Sie wirklich die Migration abschließen?",
  finished: "Die Migration wurde abgeschlossen.",
  grossPrices: "Preise inkl. Steuern (Brutto)",
  grossPricesHelp:
    "Bitte gib hier an, ob du die Artikelpreise im Shop inkl. Steuern pflegst.",
  migrationActive: "Initialen Datenimport aktivieren",
  migrationMissing:
    "Diese Integration kann erst verwendet werden, wenn der initiale Datenimport abgeschlossen wurde.",
  migrationStrategy: "Migrationsstrategie",
  migrationStrategyHelp:
    "Um deinen Shop mit Xentral zu verbinden, müssen wir die Artikel initial zwischen beiden Systemen verknüpfen. Bitte gib an, welche Situation auf dich zutrifft.",
  name: "Name",
  primary: "Primärschlüssel",
  primaryHelp:
    "Wenn sich Artikel in beiden Systemen (Shop & Xentral) befinden, müssen wir wissen, anhand welches Feldes wir die Zuordnung der Artikel machen sollen. Dazu gib bitte hier den Primärschlüssel an, also das Feld in Xentral, welches sich für den Abgleich der Produkte eignet.",
  productive: "Produktiv",
  productNumber: "Artikelnummer",
  shop: "Es befinden sich Artikel nur im Shop",
  shopAndXentral: "Es befinden sich Artikel sowohl im Shop als auch in Xentral",
  start: "Integration speichern & Initial Import von Artikeln starten",
  started: "Die Migration wurde gestartet.",
  noChannelSelected: "Es wurde keine Integration ausgewählt.",
  noChannelsFound:
    "Es konnten keine Integrationen für die Migration zu Connect gefunden werden.",
  migrateExistingIntegration: "Integration übernehmen",
  selectIntegration: "Welche Integration soll migriert werden?",
  integrationSetupConfirmation:
    'Durch Klicken auf "Integration übernehmen" wird die Migration der Integration abgeschlossen. Um die neue Integration nutzen zu können, beachte bitte folgende Hinweise:<br><br>' +
    '1. Kontrolliere die Auswahl der für die Übertragung ausgewählten Artikel im Tab "Artikelzuordnung". Du kannst die Filtereinstellungen jederzeit ändern. Die Liste der ausgewählten Artikel findest du im Bereich "Journal". Nur hier aufgelistete Artikel werden in deinen Shop synchronisiert.<br><br>' +
    "2. Bitte kontrolliere die Konfigurationseinstellungen der einzelnen Workflows. Wir haben die bereits bestehende Konfiguration automatisch übernommen. Du kannst diese Einstellungen aber jederzeit ändern.<br><br>" +
    "3. Artikel aus Xentral werden anhand der Artikelnummern zu den Artikeln im Shop zugeordnet. Wenn du Artikel auch updaten, statt nur anlegen willst, stelle bitte unbedingt sicher, dass die Artikelnummern aus Xentral mit den Artikelnummern bzw. SKUs aus dem Shop übereinstimmen.<br><br>" +
    "4. Online-Shop-spezifische Produktinformationen werden nicht übernommen. Bitte stelle sicher, dass deine Produktdaten für alle deine Online-Shops geeignet sind.<br><br>" +
    '5. Per Voreinstellung wird der Standardpreis des Artikels übertragen. Wenn du einen spezifischen Preis (bspw. eines Kunden oder einer Kundengruppe) an deinen Shop übertragen willst, kannst du dies im Bereich "Workflows" im Tab "Preise" einstellen.<br><br>' +
    "6. Um die neue Schnittstelle zu aktivieren, muss für jeden Workflow der Live-Modus aktiviert werden. Bitte achte unbedingt darauf, dass du vorher die alten, noch aktiven Schnittstellen für diesen Workflow deaktivierst, um Konflikte zu vermeiden.",
  shopifyIntegrationLink:
    'Weitere hilfreiche Informationen findest du <a href="https://help.xentral.com/hc/de/sections/13235038667548-NEW-Shopify-connector-Xentral-Connect-beta" target="_blank">hier</a>.',
  shopware6IntegrationLink:
    'Weitere hilfreiche Informationen findest du <a href="https://help.xentral.com/hc/de/sections/14527994696604-NEW-Shopware-6-connector-Xentral-Connect-beta" target="_blank">hier</a>.',
  woocommerceIntegrationLink:
    'Weitere hilfreiche Informationen findest du <a href="https://help.xentral.com/hc/de/sections/13235026588060-NEW-WooCommerce-connector-Xentral-Connect-beta" target="_blank">hier</a>.',
  formularTitle:
    "Für die Einrichtung der Integration sind folgende Angaben erforderlich:",
  form: {
    reducedTaxRateCollectionId: "Collection für reduzierten Steuersatz",
    reducedTaxRateCollectionIdHelp:
      'In Shopify werden Artikel über Collections einem reduzierten Steuersatz zugewiesen. Diese Collection muss im Shop angelegt und die ID der Collection (z.B. "gid://shopify/Collection/452487217835") hier eingetragen werden.',
    exemptedTaxCollectionId: "Collection für befreiten Steuersatz",
    exemptedTaxCollectionIdHelp:
      'In Shopify werden Artikel über Collections einem befreiten Steuersatz zugewiesen. Diese Collection muss im Shop angelegt und die ID der Collection (z.B. "gid://shopify/Collection/452487217835") hier eingetragen werden.',
    id: "Zugangs-ID",
    idHelp:
      'Die Zugangs-ID finden Sie im Shopware 6 Backend unter "Einstellungen > System > Integrationen"',
    secret: "Sicherheitsschlüssel",
    secretHelp:
      'Den Sicherheitsschlüssel finden Sie im Shopware 6 Backend unter "Einstellungen > System > Integrationen". Der Sicherheitsschlüssel wird nur beim Erstellen der Integration angezeigt.',
    selectionStrategy: "Artikelzuordnung",
    selectionStrategyHelp:
      "Wie werden die zwischen Xentral und Shop synchronisierten Artikel identifiziert?",
    externalReferences:
      "Artikelzuordnung aus der bestehenden Integration übernehmen",
    ruleBased:
      "Artikel anhand einer definierten Regel (z.B. Projekt, Freifeld etc.) zuordnen",
    syncImages: "Bilderübertragung",
    syncImagesHelp:
      "Achtung: Wenn die Bildübertragung aktiviert ist, müssen alle Bilder in Xentral gepflegt sein. Bilder, die nur im Shop und nicht in Xentral vorhanden sind, werden durch die Synchronisierung entfernt."
  },
  projectCreateFailure:
    "Projektanlage fehlgeschlagen. Bitte kontaktiere den Support.",
  migrationSuccessful: "Die Integration wurde erfolgreich übernommen.",
  migrationFailure:
    "Die Migration ist fehlgeschlagen. Bitte kontaktiere den Support."
};
