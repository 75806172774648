import Project from "@/components/Settings/Projects/project";
import { reactive, ref } from "vue";
import Assignments from "@/components/Assignments/assignments";
import Presets from "@/components/Settings/Presets/presets";
import Forms from "@/components/ExternalApps/classes/Forms";
import _ from "lodash";
import { fieldDefaults } from "@/components/Tools/FormHelper/Helper/constants";
import AuthValues from "@/components/Settings/Auth/authValues";

const state = ref({
  apps: [
    {
      id: 1,
      name: "shopify",
      useOAuth: true,
      hasFeatureWhitelist: true,
      label: "Shopify",
      logo: process.env.BASE_URL + "media/shopLogos/shopify.png",
      additional_params: {
        requestHeaders: {
          "X-Shopify-Access-Token": "{{token}}",
          "Content-Type": "application/json"
        },
        options: {
          json: {
            query: "query { shop { name } }"
          }
        },
        requestMethod: "POST",
        endpoint: "/admin/api/2024-04/graphql.json"
      },
      environmentVariables: {
        oauth: {
          canToggleLegacyConfig: true,
          client_id: process?.env?.VUE_APP_SHOPIFY_APP_CLIENT_ID,
          redirect_uri:
            process?.env?.VUE_APP_SHOPIFY_APP_APPLICATION_REDIRECT_URL
        },
        presetId: process.env?.VUE_APP_SCM_APP_SHOPIFY_PRESET_ID,
        testWorkflows: {
          product: process.env?.VUE_APP_SCM_APP_TEST_PRODUCT_WORKFLOW_ID,
          salesOrder:
            process.env?.VUE_APP_SCM_APP_SHOPIFY_TEST_ORDER_WORKFLOW_ID,
          stock: process.env?.VUE_APP_SCM_APP_TEST_STOCK_WORKFLOW_ID,
          prices: process.env?.VUE_APP_SCM_APP_TEST_PRICES_WORKFLOW_ID,
          tracking: process.env?.VUE_APP_SCM_APP_TEST_TRACKING_WORKFLOW_ID,
          category: process.env?.VUE_APP_SCM_APP_TEST_CATEGORY_WORKFLOW_ID
        },
        testBatchWorkflows: {
          product:
            process.env?.VUE_APP_SCM_APP_SHOPIFY_TEST_BATCH_PRODUCT_WORKFLOW_ID,
          salesOrder:
            process.env?.VUE_APP_SCM_APP_SHOPIFY_TEST_BATCH_ORDER_WORKFLOW_ID,
          stock:
            process.env?.VUE_APP_SCM_APP_SHOPIFY_TEST_BATCH_STOCK_WORKFLOW_ID,
          prices:
            process.env?.VUE_APP_SCM_APP_SHOPIFY_TEST_BATCH_PRICES_WORKFLOW_ID,
          tracking:
            process.env
              ?.VUE_APP_SCM_APP_SHOPIFY_TEST_BATCH_TRACKING_WORKFLOW_ID,
          category:
            process.env?.VUE_APP_SCM_APP_SHOPIFY_TEST_BATCH_CATEGORY_WORKFLOW_ID
        },
        migrationWorkflows: {
          export: {
            all: process.env
              ?.VUE_APP_SCM_APP_SHOPIFY_MIGRATION_EXPORT_WORKFLOW_ID,
            single:
              process.env
                ?.VUE_APP_SCM_APP_SHOPIFY_MIGRATION_SINGLE_EXPORT_WORKFLOW_ID
          },
          import: {
            all: process.env?.VUE_APP_SCM_APP_MIGRATION_IMPORT_WORKFLOW_ID,
            single:
              process.env?.VUE_APP_SCM_APP_MIGRATION_SINGLE_IMPORT_WORKFLOW_ID
          }
        },
        xentralWorkflowId:
          process.env.VUE_APP_SCM_APP_SHOPIFY_REPORTING_XENTRAL_WORKFLOW_ID,
        CFLWorkflowId:
          process.env.VUE_APP_SCM_APP_SHOPIFY_REPORTING_CFL_WORKFLOW_ID,
        CFLDataSetId:
          process.env.VUE_APP_SCM_APP_SHOPIFY_REPORTING_CFL_DATASET_ID,
        syncBatchWorkflowId:
          process.env.VUE_APP_SCM_APP_SHOPIFY_REPORTING_SYNC_BATCH_WORKFLOW_ID,
        shopImporterWorkflowId:
          process.env.VUE_APP_SCM_APP_SHOPIFY_MIGRATION_SHOPIMPORTER_WORKFLOW_ID
      }
    },
    {
      id: 2,
      name: "shopware6",
      label: "Shopware 6",
      logo: process.env.BASE_URL + "media/shopLogos/shopware.png",
      additional_params: {
        requestHeaders: {
          "Content-Type": "application/json"
        },
        body: {
          grant_type: "client_credentials",
          client_id: "{{id}}",
          client_secret: "{{secret}}"
        },
        requestMethod: "POST",
        endpoint: "/api/oauth/token"
      },
      areaBlacklist: ["migration"],
      environmentVariables: {
        presetId: process.env?.VUE_APP_SCM_APP_SHOPWARE6_PRESET_ID,
        testWorkflows: {
          product: process.env?.VUE_APP_SCM_APP_TEST_PRODUCT_WORKFLOW_ID,
          salesOrder:
            process.env?.VUE_APP_SCM_APP_SHOPWARE6_TEST_ORDER_WORKFLOW_ID,
          stock: process.env?.VUE_APP_SCM_APP_TEST_STOCK_WORKFLOW_ID,
          prices: process.env?.VUE_APP_SCM_APP_TEST_PRICES_WORKFLOW_ID,
          tracking: process.env?.VUE_APP_SCM_APP_TEST_TRACKING_WORKFLOW_ID,
          category: process.env?.VUE_APP_SCM_APP_TEST_CATEGORY_WORKFLOW_ID
        },
        testBatchWorkflows: {
          product:
            process.env
              ?.VUE_APP_SCM_APP_SHOPWARE6_TEST_BATCH_PRODUCT_WORKFLOW_ID,
          salesOrder:
            process.env?.VUE_APP_SCM_APP_SHOPWARE6_TEST_BATCH_ORDER_WORKFLOW_ID,
          stock:
            process.env?.VUE_APP_SCM_APP_SHOPWARE6_TEST_BATCH_STOCK_WORKFLOW_ID,
          prices:
            process.env
              ?.VUE_APP_SCM_APP_SHOPWARE6_TEST_BATCH_PRICES_WORKFLOW_ID,
          tracking:
            process.env
              ?.VUE_APP_SCM_APP_SHOPWARE6_TEST_BATCH_TRACKING_WORKFLOW_ID,
          category:
            process.env
              ?.VUE_APP_SCM_APP_SHOPWARE6_TEST_BATCH_CATEGORY_WORKFLOW_ID
        },
        migrationWorkflows: {
          export: {
            all: process.env
              ?.VUE_APP_SCM_APP_SHOPWARE6_MIGRATION_EXPORT_WORKFLOW_ID,
            single:
              process.env
                ?.VUE_APP_SCM_APP_SHOPWARE6_MIGRATION_SINGLE_EXPORT_WORKFLOW_ID
          },
          import: {
            all: process.env?.VUE_APP_SCM_APP_MIGRATION_IMPORT_WORKFLOW_ID,
            single:
              process.env?.VUE_APP_SCM_APP_MIGRATION_SINGLE_IMPORT_WORKFLOW_ID
          }
        },
        xentralWorkflowId:
          process.env.VUE_APP_SCM_APP_SHOPWARE6_REPORTING_XENTRAL_WORKFLOW_ID,
        CFLWorkflowId:
          process.env.VUE_APP_SCM_APP_SHOPWARE6_REPORTING_CFL_WORKFLOW_ID,
        CFLDataSetId:
          process.env.VUE_APP_SCM_APP_SHOPWARE6_REPORTING_CFL_DATASET_ID,
        syncBatchWorkflowId:
          process.env
            .VUE_APP_SCM_APP_SHOPWARE6_REPORTING_SYNC_BATCH_WORKFLOW_ID,
        shopImporterWorkflowId:
          process.env
            .VUE_APP_SCM_APP_SHOPWARE6_MIGRATION_SHOPIMPORTER_WORKFLOW_ID
      }
    },
    {
      id: 3,
      name: "amazon",
      label: "Amazon",
      useOAuth: true,
      logo: process.env.BASE_URL + "media/shopLogos/amazon.webp",
      areaBlacklist: ["reporting"],
      environmentVariables: {
        oauth: {
          version: "beta",
          application_id: process?.env?.VUE_APP_AMAZON_APP_APPLICATION_ID,
          redirect_uri:
            process?.env?.VUE_APP_AMAZON_APP_APPLICATION_REDIRECT_URL
        },
        presetId: process.env?.VUE_APP_SCM_APP_AMAZON_PRESET_ID,
        testWorkflows: {
          product: process.env?.VUE_APP_SCM_APP_TEST_PRODUCT_WORKFLOW_ID,
          salesOrder:
            process.env?.VUE_APP_SCM_APP_AMAZON_TEST_ORDER_WORKFLOW_ID,
          stock: process.env?.VUE_APP_SCM_APP_TEST_STOCK_WORKFLOW_ID,
          prices: process.env?.VUE_APP_SCM_APP_TEST_PRICES_WORKFLOW_ID,
          tracking: process.env?.VUE_APP_SCM_APP_TEST_TRACKING_WORKFLOW_ID,
          category: process.env?.VUE_APP_SCM_APP_TEST_CATEGORY_WORKFLOW_ID
        },
        testBatchWorkflows: {
          product:
            process.env?.VUE_APP_SCM_APP_AMAZON_TEST_BATCH_PRODUCT_WORKFLOW_ID,
          salesOrder:
            process.env?.VUE_APP_SCM_APP_AMAZON_TEST_BATCH_ORDER_WORKFLOW_ID,
          stock:
            process.env?.VUE_APP_SCM_APP_AMAZON_TEST_BATCH_STOCK_WORKFLOW_ID,
          prices:
            process.env?.VUE_APP_SCM_APP_AMAZON_TEST_BATCH_PRICES_WORKFLOW_ID,
          tracking:
            process.env?.VUE_APP_SCM_APP_AMAZON_TEST_BATCH_TRACKING_WORKFLOW_ID,
          category:
            process.env?.VUE_APP_SCM_APP_AMAZON_TEST_BATCH_CATEGORY_WORKFLOW_ID
        },
        migrationWorkflows: {
          export: {
            all: process.env
              ?.VUE_APP_SCM_APP_AMAZON_MIGRATION_EXPORT_WORKFLOW_ID,
            single:
              process.env
                ?.VUE_APP_SCM_APP_AMAZON_MIGRATION_SINGLE_EXPORT_WORKFLOW_ID
          },
          import: {
            all: process.env?.VUE_APP_SCM_APP_MIGRATION_IMPORT_WORKFLOW_ID,
            single:
              process.env?.VUE_APP_SCM_APP_MIGRATION_SINGLE_IMPORT_WORKFLOW_ID
          }
        },
        xentralWorkflowId:
          process.env.VUE_APP_SCM_APP_AMAZON_REPORTING_XENTRAL_WORKFLOW_ID,
        CFLWorkflowId:
          process.env.VUE_APP_SCM_APP_AMAZON_REPORTING_CFL_WORKFLOW_ID,
        CFLDataSetId:
          process.env.VUE_APP_SCM_APP_AMAZON_REPORTING_CFL_DATASET_ID
      }
    },
    {
      id: 4,
      name: "woocommerce",
      label: "WooCommerce",
      logo: process.env.BASE_URL + "media/shopLogos/wooCommerce.png",
      additional_params: {
        requestHeaders: {
          "Content-Type": "application/json"
        },
        endpoint: "/wp-json/wc/v3/system_status",
        authType: "oauth1"
      },
      environmentVariables: {
        presetId: process.env?.VUE_APP_SCM_APP_WOOCOMMERCE_PRESET_ID,
        testWorkflows: {
          product: process.env?.VUE_APP_SCM_APP_TEST_PRODUCT_WORKFLOW_ID,
          salesOrder:
            process.env?.VUE_APP_SCM_APP_WOOCOMMERCE_TEST_ORDER_WORKFLOW_ID,
          stock: process.env?.VUE_APP_SCM_APP_TEST_STOCK_WORKFLOW_ID,
          prices: process.env?.VUE_APP_SCM_APP_TEST_PRICES_WORKFLOW_ID,
          tracking: process.env?.VUE_APP_SCM_APP_TEST_TRACKING_WORKFLOW_ID,
          category: process.env?.VUE_APP_SCM_APP_TEST_CATEGORY_WORKFLOW_ID
        },
        testBatchWorkflows: {
          product:
            process.env
              ?.VUE_APP_SCM_APP_WOOCOMMERCE_TEST_BATCH_PRODUCT_WORKFLOW_ID,
          salesOrder:
            process.env
              ?.VUE_APP_SCM_APP_WOOCOMMERCE_TEST_BATCH_ORDER_WORKFLOW_ID,
          stock:
            process.env
              ?.VUE_APP_SCM_APP_WOOCOMMERCE_TEST_BATCH_STOCK_WORKFLOW_ID,
          prices:
            process.env
              ?.VUE_APP_SCM_APP_WOOCOMMERCE_TEST_BATCH_PRICES_WORKFLOW_ID,
          tracking:
            process.env
              ?.VUE_APP_SCM_APP_WOOCOMMERCE_TEST_BATCH_TRACKING_WORKFLOW_ID,
          category:
            process.env
              ?.VUE_APP_SCM_APP_WOOCOMMERCE_TEST_BATCH_CATEGORY_WORKFLOW_ID
        },
        migrationWorkflows: {
          export: {
            all: process.env
              ?.VUE_APP_SCM_APP_WOOCOMMERCE_MIGRATION_EXPORT_WORKFLOW_ID,
            single:
              process.env
                ?.VUE_APP_SCM_APP_WOOCOMMERCE_MIGRATION_SINGLE_EXPORT_WORKFLOW_ID
          },
          import: {
            all: process.env?.VUE_APP_SCM_APP_MIGRATION_IMPORT_WORKFLOW_ID,
            single:
              process.env?.VUE_APP_SCM_APP_MIGRATION_SINGLE_IMPORT_WORKFLOW_ID
          }
        },
        xentralWorkflowId:
          process.env.VUE_APP_SCM_APP_WOOCOMMERCE_REPORTING_XENTRAL_WORKFLOW_ID,
        CFLWorkflowId:
          process.env.VUE_APP_SCM_APP_WOOCOMMERCE_REPORTING_CFL_WORKFLOW_ID,
        CFLDataSetId:
          process.env.VUE_APP_SCM_APP_WOOCOMMERCE_REPORTING_CFL_DATASET_ID,
        syncBatchWorkflowId:
          process.env
            .VUE_APP_SCM_APP_WOOCOMMERCE_REPORTING_SYNC_BATCH_WORKFLOW_ID,
        shopImporterWorkflowId:
          process.env
            .VUE_APP_SCM_APP_WOOCOMMERCE_MIGRATION_SHOPIMPORTER_WORKFLOW_ID
      }
    },
    {
      id: 5,
      name: "magento",
      label: "Magento",
      logo: process.env.BASE_URL + "media/shopLogos/magento.png"
    },
    {
      id: 6,
      name: "shopware5",
      label: "Shopware 5",
      logo: process.env.BASE_URL + "media/shopLogos/shopware.png",
      areaBlacklist: [
        "workflows",
        "productSelection",
        "wizard.baseData",
        "reporting",
        "journal"
      ],
      additional_params: {
        endpoint: "/api/version",
        authType: "digest"
      },
      environmentVariables: {
        presetId: process.env?.VUE_APP_SCM_APP_SHOPWARE5_PRESET_ID,
        testWorkflows: {
          product: process.env?.VUE_APP_SCM_APP_TEST_PRODUCT_WORKFLOW_ID,
          salesOrder:
            process.env?.VUE_APP_SCM_APP_SHOPWARE5_TEST_ORDER_WORKFLOW_ID,
          stock: process.env?.VUE_APP_SCM_APP_TEST_STOCK_WORKFLOW_ID,
          prices: process.env?.VUE_APP_SCM_APP_TEST_PRICES_WORKFLOW_ID,
          tracking: process.env?.VUE_APP_SCM_APP_TEST_TRACKING_WORKFLOW_ID,
          category: process.env?.VUE_APP_SCM_APP_TEST_CATEGORY_WORKFLOW_ID
        },
        migrationWorkflows: {
          export: {
            all: process.env
              ?.VUE_APP_SCM_APP_SHOPWARE5_MIGRATION_EXPORT_WORKFLOW_ID,
            single:
              process.env
                ?.VUE_APP_SCM_APP_SHOPWARE5_MIGRATION_SINGLE_EXPORT_WORKFLOW_ID
          },
          import: {
            all: process.env?.VUE_APP_SCM_APP_MIGRATION_IMPORT_WORKFLOW_ID,
            single:
              process.env?.VUE_APP_SCM_APP_MIGRATION_SINGLE_IMPORT_WORKFLOW_ID
          }
        },
        xentralWorkflowId:
          process.env.VUE_APP_SCM_APP_SHOPWARE5_REPORTING_XENTRAL_WORKFLOW_ID,
        CFLWorkflowId:
          process.env.VUE_APP_SCM_APP_SHOPWARE5_REPORTING_CFL_WORKFLOW_ID,
        CFLDataSetId:
          process.env.VUE_APP_SCM_APP_SHOPWARE5_REPORTING_CFL_DATASET_ID
      },
      hasOnlyMigration: true
    },
    {
      id: 7,
      name: "bigcommerce",
      label: "BigCommerce",
      logo: process.env.BASE_URL + "media/shopLogos/bigcommerce.webp",
      areaBlacklist: ["migration", "reporting"],
      additional_params: {
        requestHeaders: {
          "X-Auth-Token": "{{token}}",
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        endpoint: "/v2/store"
      },
      environmentVariables: {
        presetId: process.env?.VUE_APP_SCM_APP_BIGCOMMERCE_PRESET_ID,
        testWorkflows: {
          product: process.env?.VUE_APP_SCM_APP_TEST_PRODUCT_WORKFLOW_ID,
          salesOrder:
            process.env?.VUE_APP_SCM_APP_BIGCOMMERCE_TEST_ORDER_WORKFLOW_ID,
          stock: process.env?.VUE_APP_SCM_APP_TEST_STOCK_WORKFLOW_ID,
          prices: process.env?.VUE_APP_SCM_APP_TEST_PRICES_WORKFLOW_ID,
          tracking: process.env?.VUE_APP_SCM_APP_TEST_TRACKING_WORKFLOW_ID,
          category: process.env?.VUE_APP_SCM_APP_TEST_CATEGORY_WORKFLOW_ID
        },
        testBatchWorkflows: {
          product:
            process.env
              ?.VUE_APP_SCM_APP_BIGCOMMERCE_TEST_BATCH_PRODUCT_WORKFLOW_ID,
          salesOrder:
            process.env
              ?.VUE_APP_SCM_APP_BIGCOMMERCE_TEST_BATCH_ORDER_WORKFLOW_ID,
          stock:
            process.env
              ?.VUE_APP_SCM_APP_BIGCOMMERCE_TEST_BATCH_STOCK_WORKFLOW_ID,
          prices:
            process.env
              ?.VUE_APP_SCM_APP_BIGCOMMERCE_TEST_BATCH_PRICES_WORKFLOW_ID,
          tracking:
            process.env
              ?.VUE_APP_SCM_APP_BIGCOMMERCE_TEST_BATCH_TRACKING_WORKFLOW_ID,
          category:
            process.env
              ?.VUE_APP_SCM_APP_BIGCOMMERCE_TEST_BATCH_CATEGORY_WORKFLOW_ID
        },
        migrationWorkflows: {
          export: {
            all: process.env
              ?.VUE_APP_SCM_APP_BIGCOMMERCE_MIGRATION_EXPORT_WORKFLOW_ID,
            single:
              process.env
                ?.VUE_APP_SCM_APP_BIGCOMMERCE_MIGRATION_SINGLE_EXPORT_WORKFLOW_ID
          },
          import: {
            all: process.env?.VUE_APP_SCM_APP_MIGRATION_IMPORT_WORKFLOW_ID,
            single:
              process.env?.VUE_APP_SCM_APP_MIGRATION_SINGLE_IMPORT_WORKFLOW_ID
          }
        },
        xentralWorkflowId:
          process.env.VUE_APP_SCM_APP_BIGCOMMERCE_REPORTING_XENTRAL_WORKFLOW_ID,
        CFLWorkflowId:
          process.env.VUE_APP_SCM_APP_BIGCOMMERCE_REPORTING_CFL_WORKFLOW_ID,
        CFLDataSetId:
          process.env.VUE_APP_SCM_APP_BIGCOMMERCE_REPORTING_CFL_DATASET_ID
      }
    },
    {
      id: 8,
      name: "otto",
      useOAuth: true,
      label: "Otto",
      logo: process.env.BASE_URL + "media/shopLogos/otto.png",
      areaBlacklist: ["migration", "reporting"],
      additional_params: {
        requestHeaders: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json"
        },
        body: {
          grant_type: "client_credentials"
        },
        requestMethod: "POST",
        endpoint: "/oauth2/token"
      },
      environmentVariables: {
        oauth: {
          canToggleSandbox: true,
          response_type: "code",
          client_id: process?.env?.VUE_APP_OTTO_APP_CLIENT_ID,
          scope: "installation partnerId"
        },
        presetId: process.env?.VUE_APP_SCM_APP_OTTO_PRESET_ID,
        testWorkflows: {
          product: process.env?.VUE_APP_SCM_APP_TEST_PRODUCT_WORKFLOW_ID,
          salesOrder: process.env?.VUE_APP_SCM_APP_OTTO_TEST_ORDER_WORKFLOW_ID,
          stock: process.env?.VUE_APP_SCM_APP_TEST_STOCK_WORKFLOW_ID,
          prices: process.env?.VUE_APP_SCM_APP_TEST_PRICES_WORKFLOW_ID,
          tracking: process.env?.VUE_APP_SCM_APP_TEST_TRACKING_WORKFLOW_ID,
          category: process.env?.VUE_APP_SCM_APP_TEST_CATEGORY_WORKFLOW_ID
        },
        testBatchWorkflows: {
          product:
            process.env?.VUE_APP_SCM_APP_OTTO_TEST_BATCH_PRODUCT_WORKFLOW_ID,
          salesOrder:
            process.env?.VUE_APP_SCM_APP_OTTO_TEST_BATCH_ORDER_WORKFLOW_ID,
          stock: process.env?.VUE_APP_SCM_APP_OTTO_TEST_BATCH_STOCK_WORKFLOW_ID,
          prices:
            process.env?.VUE_APP_SCM_APP_OTTO_TEST_BATCH_PRICES_WORKFLOW_ID,
          tracking:
            process.env?.VUE_APP_SCM_APP_OTTO_TEST_BATCH_TRACKING_WORKFLOW_ID,
          category:
            process.env?.VUE_APP_SCM_APP_OTTO_TEST_BATCH_CATEGORY_WORKFLOW_ID
        },
        migrationWorkflows: {
          export: {
            all: process.env?.VUE_APP_SCM_APP_OTTO_MIGRATION_EXPORT_WORKFLOW_ID,
            single:
              process.env
                ?.VUE_APP_SCM_APP_OTTO_MIGRATION_SINGLE_EXPORT_WORKFLOW_ID
          },
          import: {
            all: process.env?.VUE_APP_SCM_APP_MIGRATION_IMPORT_WORKFLOW_ID,
            single:
              process.env?.VUE_APP_SCM_APP_MIGRATION_SINGLE_IMPORT_WORKFLOW_ID
          }
        },
        xentralWorkflowId:
          process.env.VUE_APP_SCM_APP_OTTO_REPORTING_XENTRAL_WORKFLOW_ID,
        CFLWorkflowId:
          process.env.VUE_APP_SCM_APP_OTTO_REPORTING_CFL_WORKFLOW_ID,
        CFLDataSetId: process.env.VUE_APP_SCM_APP_OTTO_REPORTING_CFL_DATASET_ID
      }
    },
    {
      id: 9,
      name: "hornbach",
      label: "Mirakl Hornbach",
      logo: process.env.BASE_URL + "media/shopLogos/mirakl_hornbach.svg",
      areaBlacklist: ["migration"],
      additional_params: {
        requestHeaders: {
          Authorization: "{{key}}",
          Accept: "application/json"
        },
        requestMethod: "GET",
        endpoint: "/api/account"
      },
      environmentVariables: {
        presetId: process.env?.VUE_APP_SCM_APP_MIRAKL_HORNBACH_PRESET_ID,
        xentralWorkflowId:
          process.env.VUE_APP_SCM_APP_HORNBACH_REPORTING_XENTRAL_WORKFLOW_ID,
        CFLWorkflowId:
          process.env.VUE_APP_SCM_APP_HORNBACH_REPORTING_CFL_WORKFLOW_ID,
        CFLDataSetId:
          process.env.VUE_APP_SCM_APP_HORNBACH_REPORTING_CFL_DATASET_ID,
        product: {
          initialWorkflowId:
            process.env?.VUE_APP_SCM_APP_HORNBACH_INITIAL_PRODUCT_WORKFLOW_ID
        }
      }
    },
    {
      id: 10,
      name: "oxid",
      label: "Oxid",
      logo: process.env.BASE_URL + "media/shopLogos/oxid.svg",
      additional_params: {
        requestHeaders: {
          "Content-Type": "application/json"
        },
        endpoint: "/",
        authType: "digest"
      },
      environmentVariables: {
        presetId: process.env?.VUE_APP_SCM_APP_OXID_PRESET_ID,
        testWorkflows: {
          product: process.env?.VUE_APP_SCM_APP_TEST_PRODUCT_WORKFLOW_ID,
          salesOrder: process.env?.VUE_APP_SCM_APP_OXID_TEST_ORDER_WORKFLOW_ID,
          stock: process.env?.VUE_APP_SCM_APP_TEST_STOCK_WORKFLOW_ID,
          prices: process.env?.VUE_APP_SCM_APP_TEST_PRICES_WORKFLOW_ID,
          tracking: process.env?.VUE_APP_SCM_APP_TEST_TRACKING_WORKFLOW_ID,
          category: process.env?.VUE_APP_SCM_APP_TEST_CATEGORY_WORKFLOW_ID
        },
        testBatchWorkflows: {
          product:
            process.env?.VUE_APP_SCM_APP_OXID_TEST_BATCH_PRODUCT_WORKFLOW_ID,
          salesOrder:
            process.env?.VUE_APP_SCM_APP_OXID_TEST_BATCH_ORDER_WORKFLOW_ID,
          stock: process.env?.VUE_APP_SCM_APP_OXID_TEST_BATCH_STOCK_WORKFLOW_ID,
          prices:
            process.env?.VUE_APP_SCM_APP_OXID_TEST_BATCH_PRICES_WORKFLOW_ID,
          tracking:
            process.env?.VUE_APP_SCM_APP_OXID_TEST_BATCH_TRACKING_WORKFLOW_ID,
          category:
            process.env?.VUE_APP_SCM_APP_OXID_TEST_BATCH_CATEGORY_WORKFLOW_ID
        },
        migrationWorkflows: {
          export: {
            all: process.env?.VUE_APP_SCM_APP_OXID_MIGRATION_EXPORT_WORKFLOW_ID,
            single:
              process.env
                ?.VUE_APP_SCM_APP_OXID_MIGRATION_SINGLE_EXPORT_WORKFLOW_ID
          },
          import: {
            all: process.env?.VUE_APP_SCM_APP_MIGRATION_IMPORT_WORKFLOW_ID,
            single:
              process.env?.VUE_APP_SCM_APP_MIGRATION_SINGLE_IMPORT_WORKFLOW_ID
          }
        },
        xentralWorkflowId:
          process.env.VUE_APP_SCM_APP_OXID_REPORTING_XENTRAL_WORKFLOW_ID,
        CFLWorkflowId:
          process.env.VUE_APP_SCM_APP_OXID_REPORTING_CFL_WORKFLOW_ID,
        CFLDataSetId: process.env.VUE_APP_SCM_APP_OXID_REPORTING_CFL_DATASET_ID,
        syncBatchWorkflowId:
          process.env.VUE_APP_SCM_APP_OXID_REPORTING_SYNC_BATCH_WORKFLOW_ID,
        shopImporterWorkflowId:
          process.env.VUE_APP_SCM_APP_OXID_MIGRATION_SHOPIMPORTER_WORKFLOW_ID
      }
    },
    {
      id: 11,
      name: "tradebyte",
      label: "Tradebyte",
      logo: process.env.BASE_URL + "media/shopLogos/tradebyte.png",
      additional_params: {
        requestHeaders: {
          "Content-Type": "application/json"
        },
        endpoint: "",
        authType: "skip"
      },
      environmentVariables: {
        presetId: process.env?.VUE_APP_SCM_APP_TRADEBYTE_PRESET_ID,
        testWorkflows: {
          product: process.env?.VUE_APP_SCM_APP_TEST_PRODUCT_WORKFLOW_ID,
          salesOrder:
            process.env?.VUE_APP_SCM_APP_TRADEBYTE_TEST_ORDER_WORKFLOW_ID,
          stock: process.env?.VUE_APP_SCM_APP_TEST_STOCK_WORKFLOW_ID,
          prices: process.env?.VUE_APP_SCM_APP_TEST_PRICES_WORKFLOW_ID,
          tracking: process.env?.VUE_APP_SCM_APP_TEST_TRACKING_WORKFLOW_ID,
          category: process.env?.VUE_APP_SCM_APP_TEST_CATEGORY_WORKFLOW_ID
        },
        testBatchWorkflows: {
          product:
            process.env
              ?.VUE_APP_SCM_APP_TRADEBYTE_TEST_BATCH_PRODUCT_WORKFLOW_ID,
          salesOrder:
            process.env?.VUE_APP_SCM_APP_TRADEBYTE_TEST_BATCH_ORDER_WORKFLOW_ID,
          stock:
            process.env?.VUE_APP_SCM_APP_TRADEBYTE_TEST_BATCH_STOCK_WORKFLOW_ID,
          prices:
            process.env
              ?.VUE_APP_SCM_APP_TRADEBYTE_TEST_BATCH_PRICES_WORKFLOW_ID,
          tracking:
            process.env
              ?.VUE_APP_SCM_APP_TRADEBYTE_TEST_BATCH_TRACKING_WORKFLOW_ID,
          category:
            process.env
              ?.VUE_APP_SCM_APP_TRADEBYTE_TEST_BATCH_CATEGORY_WORKFLOW_ID
        },
        migrationWorkflows: {
          export: {
            all: process.env
              ?.VUE_APP_SCM_APP_TRADEBYTE_MIGRATION_EXPORT_WORKFLOW_ID,
            single:
              process.env
                ?.VUE_APP_SCM_APP_TRADEBYTE_MIGRATION_SINGLE_EXPORT_WORKFLOW_ID
          },
          import: {
            all: process.env?.VUE_APP_SCM_APP_MIGRATION_IMPORT_WORKFLOW_ID,
            single:
              process.env?.VUE_APP_SCM_APP_MIGRATION_SINGLE_IMPORT_WORKFLOW_ID
          }
        },
        xentralWorkflowId:
          process.env.VUE_APP_SCM_APP_TRADEBYTE_REPORTING_XENTRAL_WORKFLOW_ID,
        CFLWorkflowId:
          process.env.VUE_APP_SCM_APP_TRADEBYTE_REPORTING_CFL_WORKFLOW_ID,
        CFLDataSetId:
          process.env.VUE_APP_SCM_APP_TRADEBYTE_REPORTING_CFL_DATASET_ID,
        syncBatchWorkflowId:
          process.env
            .VUE_APP_SCM_APP_TRADEBYTE_REPORTING_SYNC_BATCH_WORKFLOW_ID,
        shopImporterWorkflowId:
          process.env
            .VUE_APP_SCM_APP_TRADEBYTE_MIGRATION_SHOPIMPORTER_WORKFLOW_ID
      }
    }
  ],
  credentialIds: {
    presetValue: undefined,
    authValue: undefined
  },
  stateMachineDatasets: {
    product: {
      list: process.env.VUE_APP_SCM_APP_PRODUCT_STATE_MACHINE_DATASET,
      detail: process.env.VUE_APP_SCM_APP_PRODUCT_STATE_MACHINE_DETAIL_DATASET
    },
    salesOrder: {
      list: process.env.VUE_APP_SCM_APP_SALES_ORDER_STATE_MACHINE_DATASET,
      detail:
        process.env.VUE_APP_SCM_APP_SALES_ORDER_STATE_MACHINE_DETAIL_DATASET
    },
    tracking: {
      list: process.env.VUE_APP_SCM_APP_TRACKING_STATE_MACHINE_DATASET,
      detail: process.env.VUE_APP_SCM_APP_TRACKING_STATE_MACHINE_DETAIL_DATASET
    },
    price: {
      list: process.env.VUE_APP_SCM_APP_PRICE_STATE_MACHINE_DATASET,
      detail: process.env.VUE_APP_SCM_APP_PRICE_STATE_MACHINE_DETAIL_DATASET
    },
    category: {
      list: process.env.VUE_APP_SCM_APP_CATEGORY_STATE_MACHINE_DATASET,
      detail: process.env.VUE_APP_SCM_APP_CATEGORY_STATE_MACHINE_DETAIL_DATASET
    },
    stock: {
      list: process.env.VUE_APP_SCM_APP_STOCK_STATE_MACHINE_DATASET,
      detail: process.env.VUE_APP_SCM_APP_STOCK_STATE_MACHINE_DETAIL_DATASET
    }
  },
  migrationDatasetId:
    process.env.VUE_APP_SCM_APP_MIGRATION_STATE_MACHINE_DATASET,
  selectedApp: null,
  forms: {
    shopify: {},
    woocommerce: {},
    shopware5: {},
    shopware6: {},
    otto: {},
    bigcommerce: {},
    amazon: {},
    hornbach: {},
    oxid: {},
    tradebyte: {}
  },
  projects: [],
  xentralConfig: {},
  selectedIntegration: {},
  assignments: [],
  valuesToSave: {},
  deactivatedFeatures: [],
  syncBatchWorkflowId:
    process.env.VUE_APP_SCM_APP_REPORTING_SYNC_BATCH_WORKFLOW_ID,
  loadChannelsWorkflowId: process.env.VUE_APP_SCM_APP_LOAD_CHANNELS_WORKFLOW_ID,
  shopImporterLicenseIds: {
    shopware6: process.env.VUE_APP_SCM_APP_SHOPIMPORTER_LICENSEIDS_SHOPWARE6,
    shopify: process.env.VUE_APP_SCM_APP_SHOPIMPORTER_LICENSEIDS_SHOPIFY
  },
  shopImporterShopsGA: process.env.VUE_APP_SCM_APP_SHOPIMPORTER_SHOPS_GA,
  isLocal: process.env.VUE_APP_SCMA_ENVIRONMENT === "local"
});

const getters = {
  apps: state => {
    return state.apps;
  },
  app: state => name => {
    return state.apps.find(app => app.name === name);
  },
  credentialIds: state => {
    return state.credentialIds;
  },
  valuesToSave: state => {
    return state.valuesToSave;
  },
  form:
    (state, getters) =>
      (path = "") => {
        return path
          ? path.split(".").reduce(function (prev, curr) {
            return prev ? prev[curr] : null;
          }, state.forms[getters.selectedApp?.name])
          : state.forms[getters.selectedApp?.name];
      },
  forms: state => {
    return state.forms;
  },
  integrations: (state, getters) => {
    return state.projects.filter(
      project => project?.preset_id === getters.presetId
    );
  },
  integrationProject: (state, getters) => id => {
    return getters.integrations.find(project => project.id === id);
  },
  assignment: state => id => {
    return Object.values(state.assignments || []).find(
      assignment => assignment.id === id
    );
  },
  selectedApp: state => {
    return state.apps.find(app => app.id === state.selectedApp);
  },
  selectedIntegration: state => {
    return state.selectedIntegration;
  },
  xentralConfig: state => {
    return state.xentralConfig;
  },
  licenseId: state => {
    return state.xentralConfig?.data?.idToken
      ? state.xentralConfig?.data?.idToken["https://xentral.com/license-id"]
      : "";
  },
  environmentVariables: (state, getters) => {
    return getters.selectedApp.environmentVariables ?? {};
  },
  oauthVariable: (state, getters) => key => {
    return getters.environmentVariables?.oauth?.[key] ?? "";
  },
  presetId: (state, getters) => {
    return getters.environmentVariables?.presetId ?? "";
  },
  xentralPresetProjectId: state => {
    return (
      state.projects.find(project => project?.name === "Xentral")?.id ?? ""
    );
  },
  appsWithoutMigration: state => {
    return state.apps.filter(el => el?.areaBlacklist?.includes("migration"));
  },
  migrationWorkflowId:
    (state, getters) =>
      (selectedArea, type = "all") => {
        return (
        getters.environmentVariables?.migrationWorkflows?.[selectedArea]?.[
          type
        ] ?? ""
        );
      },
  testWorkflowId: (state, getters) => selectedFeature => {
    return getters.environmentVariables?.testWorkflows?.[selectedFeature] ?? "";
  },
  testBatchWorkflowId: (state, getters) => selectedFeature => {
    return (
      getters.environmentVariables?.testBatchWorkflows?.[selectedFeature] ?? ""
    );
  },
  stateMachineDataSet:
    state =>
      (feature, type = "list") =>
      state.stateMachineDatasets?.[feature]?.[type],
  migrationDatasetId: state =>
    _.memoize(function () {
      return state.migrationDatasetId ?? "";
    }),
  areaBlacklist: (state, getters) => {
    return getters.selectedApp?.areaBlacklist;
  },
  syncBatchWorkflowId: state => {
    return state.syncBatchWorkflowId;
  },
  deactivatedFeatures: state => {
    return state.deactivatedFeatures;
  },
  loadChannelsWorkflowId: state => {
    return state.loadChannelsWorkflowId;
  },
  shopImporterLicenseIds: (state, getters) => {
    return state.shopImporterLicenseIds?.[getters.selectedApp.name] ?? "";
  },
  shopImporterShopsGA: state => {
    return state.shopImporterShopsGA;
  },
  initialProductWorkflowId: (state, getters) => selectedFeature => {
    return (
      getters.environmentVariables[selectedFeature]?.initialWorkflowId ?? null
    );
  }
};

const mutations = {
  loadSelectedIntegration(state, integration) {
    state.selectedIntegration = integration;
  },
  loadProjects(state, data) {
    state.projects = data;
  },
  loadForms(state, data) {
    state.forms[data.appName] = {
      ...(state.forms[data.appName] || {}),
      ...data.form
    };
  },
  setSelectedApp(state, payload) {
    state.selectedApp = this.getters.app(payload).id;
  },
  loadAssignments(state, data) {
    state.assignments = data;
  },
  setXentralConfig(state, payload) {
    state.xentralConfig = payload;
  },
  setValuesToSave(state, payload) {
    state.valuesToSave = payload;
  },
  setCredentialIds(state, payload) {
    state.credentialIds[payload.key] = payload.id;
  },
  updateOauthEnvironment(state, payload) {
    let app = state.apps.find(app => app.name === payload.appName);
    app.environmentVariables.oauth.client_id = payload.newClientId;
    app.environmentVariables.oauth.redirect_uri = payload.redirectUri;
  },
  resetValuesToSave(state) {
    state.valuesToSave = {};
  },
  addDeactivatedFeature(state, payload) {
    state.deactivatedFeatures.push(payload);
  },
  resetDeactivatedFeatures(state) {
    state.deactivatedFeatures = [];
  },
  resetSelectedIntegrationFeatures(state) {
    state.selectedIntegration.features = {};
  }
};

/**
 * Function to save only changed values
 * @param payload
 * @param getters
 * @returns {any}
 */
function setChangedValues(payload, getters) {
  const { feature, name, value, area, features } = payload;
  const data = getters.valuesToSave;
  const integration = getters.selectedIntegration;

  let metadata, dataToSave;

  if (feature === "migration") {
    metadata = data[feature] ?? integration[feature];
    dataToSave = { [feature]: metadata };
  } else if (
    (Object.keys(features).length > 0 && features.includes(feature)) ||
    (feature === "productSelection" && area === "selection") ||
    (feature === "productSelection" && area === "selectionV2")
  ) {
    dataToSave = setChangedAreaValues(feature, area, data, integration);
  } else {
    if (area) {
      dataToSave = setChangedAreaValues(feature, area, data, integration);
    } else {
      metadata = integration[feature];
      metadata.value[name] = value;

      dataToSave = { [feature]: metadata };
    }
  }

  Object.keys(dataToSave).forEach(featureToSave => {
    data[featureToSave] = {
      ...(data[featureToSave] || {}),
      ...dataToSave[featureToSave]
    };
  });

  return data;
}

function setChangedAreaValues(feature, area, data, integration) {
  const areaKey = Object.keys(data[feature] || {}).includes(area)
    ? data[feature][area]
    : integration[feature][area];

  return { [feature]: { [area]: areaKey } };
}

async function setIntegrationData(
  integration,
  presetValues,
  authValues,
  getters
) {
  integration.features = presetValues.value.find(
    preset => preset.name === "features"
  );

  if (integration.features) {
    for (const featureKey of Object.keys(integration.features?.value || {})) {
      let areas = getters.form("app." + featureKey);

      if (!areas) {
        continue;
      }

      const areasKeys = Object.keys(areas);
      const featureValue = integration.features.value[featureKey];

      areasKeys.forEach(area => {
        const presetName = featureKey + _.upperFirst(area);
        const currentValue = presetValues.value.find(
          preset => preset.name === presetName
        );

        if (featureValue || (!featureValue && currentValue)) {
          if (!integration[featureKey]) integration[featureKey] = {};

          integration[featureKey][area] =
            currentValue ||
            reactive({
              name: presetName,
              label: presetName,
              project_id: integration.id,
              type: "json",
              // We need to get the default values of every field/field type
              value: getDefaultValuesForArea(areas[area])
            });
        }
      });
    }
  }

  integration.baseData = presetValues.value.find(
    preset => preset.name === "baseData"
  );
  integration.credentials =
    authValues.value?.find(auth => auth.name === "credentials") ??
    presetValues.value.find(preset => preset.name === "credentials");

  const settingAreas = getters.form("settings");
  integration.settings = {};

  for (const settingKey of Object.keys(settingAreas || {})) {
    const presetName = "settings" + _.upperFirst(settingKey);

    integration.settings[settingKey] =
      presetValues.value.find(preset => preset.name === presetName) ||
      reactive({
        name: presetName,
        label: presetName,
        project_id: integration.id,
        type: "json",
        // We need to get the default values of every field/field type
        value: getDefaultValuesForArea(settingAreas[settingKey])
      });
  }

  const productSelectionAreas = getters.form("productSelection");

  if (Object.keys(productSelectionAreas || {}).length) {
    // Preset: productSelectionSelection
    integration.productSelection = {};
    const presetName = "productSelectionSelection";

    integration.productSelection["selection"] =
      presetValues.value.find(preset => preset.name === presetName) ||
      reactive({
        name: presetName,
        label: presetName,
        project_id: integration.id,
        type: "json",
        // We need to get the default values of every field/field type
        value: getDefaultValuesForArea(productSelectionAreas["selection"])
      });

    integration.productsSelected = presetValues?.value?.find(
      preset => preset.name === "productsSelected"
    );

    // Preset: productSelectionSelectionV2
    const presetNameV2 = "productSelectionSelectionV2";
    integration.productSelection["selectionV2"] =
      presetValues.value.find(preset => preset.name === presetNameV2) ||
      reactive({
        name: presetNameV2,
        label: presetNameV2,
        project_id: integration.id,
        type: "json",
        value: getDefaultValuesForArea(productSelectionAreas["selection"])
      });
  }

  integration.migration = presetValues.value.find(
    preset => preset.name === "migration"
  );

  return integration;
}

function getDefaultValuesForArea(area) {
  let values = {};

  for (const field of area) {
    values[field.name] = field.default ?? fieldDefaults[field.type];
  }

  return values;
}

const actions = {
  async loadSelectedIntegration(context, integration) {
    this.dispatch("loadingQueue/addEventToLoadingQueue", {
      key: "salesChannelManagementAppLoadSelectedIntegration"
    });

    const filter = [
      {
        key: "project_id",
        op: "equals",
        value: integration.id
      }
    ];

    const params = {
      noPagination: true
    };
    const presetValues = ref();
    const authValues = ref();

    const getPresetValues = Presets.getAll(params, filter).then(response => {
      presetValues.value = response.data;
    });

    const getAuthValues = AuthValues.getAll(params, filter).then(response => {
      authValues.value = response.data;
    });

    const getAssignments = Assignments.getAll(params, filter).then(response => {
      context.commit("loadAssignments", response.data);
    });

    await Promise.all([getPresetValues, getAuthValues, getAssignments]);

    const credentialPreset = presetValues.value.find(
      preset => preset.name === "credentials"
    );
    const authValuePreset = authValues.value.find(
      auth => auth.name === "credentials"
    );

    context.state.credentialIds.presetValue = credentialPreset?.id;
    context.state.credentialIds.authValue = authValuePreset?.id;

    // Temporary solution while we wait for the new load/save logic
    setIntegrationData(
      integration,
      presetValues,
      authValues,
      this.getters
    ).then(response => {
      context.commit("loadSelectedIntegration", response);

      this.dispatch("loadingQueue/removeEventFromLoadingQueue", {
        key: "salesChannelManagementAppLoadSelectedIntegration"
      });
    });
  },

  async loadProjects(context) {
    this.dispatch("loadingQueue/addEventToLoadingQueue", {
      key: "salesChannelManagementAppGetProjects"
    });
    const params = {
      noPagination: true
    };

    await Project.getAll(params)
      .then(response => {
        context.commit("loadProjects", response.data);
      })
      .finally(() =>
        this.dispatch("loadingQueue/removeEventFromLoadingQueue", {
          key: "salesChannelManagementAppGetProjects"
        })
      );
  },

  async loadForms(context, payload) {
    this.dispatch("loadingQueue/addEventToLoadingQueue", {
      key: "salesChannelManagementAppGetForms"
    });

    const currentAppName = context.getters.selectedApp?.name;

    await Forms.getScmaForm(currentAppName, payload)
      .then(response => {
        context.commit("loadForms", {
          form: ref(response.data).value,
          appName: currentAppName
        });
      })
      .finally(() =>
        this.dispatch("loadingQueue/removeEventFromLoadingQueue", {
          key: "salesChannelManagementAppGetForms"
        })
      );
  },

  loadAssignments(context) {
    context.commit("loadAssignments");
  },

  setSelectedApp(context, payload) {
    context.commit("setSelectedApp", payload);
  },

  setXentralConfig(context, payload) {
    context.commit("setXentralConfig", payload);
  },

  setValuesToSave({ commit, getters }, payload) {
    payload = setChangedValues(payload, getters);
    commit("setValuesToSave", payload);
  },

  setCredentialIds({ commit }, payload) {
    commit("setCredentialIds", payload);
  },

  updateOauthEnvironment(context, payload) {
    context.commit("updateOauthEnvironment", payload);
  },

  resetValuesToSave(context) {
    context.commit("resetValuesToSave");
  },

  addDeactivatedFeature(context, payload) {
    context.commit("addDeactivatedFeature", payload);
  },

  resetDeactivatedFeatures(context, payload) {
    context.commit("resetDeactivatedFeatures", payload);
  },

  resetSelectedIntegrationFeatures(context, payload) {
    context.commit("resetSelectedIntegrationFeatures", payload);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
