export const settings = {
  barcode: "Barcode",
  customsTariffNumber: "Zolltarifnummer",
  ean: "EAN Nr./Barcode",
  name: "Name",
  productAssignmentLabel:
    "Wie sollen die Artikel aus Xentral den Artikeln im Onlineshop zugeordnet werden?",
  productAssignmentSelectXentral: "Artikelnummern aus Xentral übernehmen",
  productAssignmentSource: "Feld aus Xentral",
  productAssignmentTarget: "Feld aus {appName}",
  productnumber: "Artikelnummer",
  sku: "SKU",
  title: "Titel"
};
