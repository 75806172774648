import Vue from "vue";

const _ = require("lodash");

export const SET_CURRENT_ROUTE = "setCurrentRoute";

export const SET_ROUTE_PARAMS = "setRouteParams";
export const SET_ROUTE_PARAMS_BY_KEY = "setRouteParamsByKey";

export const REMOVE_ROUTE_PARAMS = "removeRouteParams";

export const REMOVE_ROUTE_PARAMS_BY_KEY = "removeRouteParamsByKey";

export const CLEAR_STATE = "clearState";

const getDefaultState = () => {
  return {
    currentRoute: "",
    params: {
      default: {}
      // Add param collections with prefix as key
      // prefix: {
      //    page: 5,
      //    perPage: 20,
      //    searchTerm: "test"
      // }
    }
  };
};
const requestProps = ["page", "perPage", "searchTerm", "filter"];

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    currentPath: state => state.currentRoute.path,
    params:
      (state, getters) =>
        (prefix = "") => {
          prefix = prefix || "default";
          return state.params?.[getters.currentPath]?.[prefix] ?? {};
        },
    requestParams:
      (state, getters) =>
        (prefix = "") => {
          let params = getters.params(prefix);
          return _.pick(params, requestProps);
        }
  },
  actions: {
    [SET_CURRENT_ROUTE](context, payload) {
      context.commit(SET_CURRENT_ROUTE, payload);
    },
    // [SET_ROUTE_PARAMS] payload = { (String) prefix = "", (Object) params }
    [SET_ROUTE_PARAMS]({ commit, getters }, payload) {
      payload.prefix = payload.prefix || "default";
      payload.currentPath = getters.currentPath;
      commit(SET_ROUTE_PARAMS, payload);
    },
    [SET_ROUTE_PARAMS_BY_KEY]({ commit, getters }, payload) {
      payload.prefix = payload.prefix || "default";
      payload.currentPath = getters.currentPath;

      commit(SET_ROUTE_PARAMS_BY_KEY, payload);
    },
    // [REMOVE_ROUTE_PARAMS] payload = (String) = ""
    [REMOVE_ROUTE_PARAMS]({ commit, getters }, prefix) {
      const payload = {
        prefix: prefix || "default",
        currentPath: getters.currentPath
      };
      commit(REMOVE_ROUTE_PARAMS, payload);
    },
    [REMOVE_ROUTE_PARAMS_BY_KEY]({ commit, getters }, payload) {
      payload.prefix = payload.prefix || "default";
      payload.currentPath = getters.currentPath;
      commit(REMOVE_ROUTE_PARAMS_BY_KEY, payload);
    },
    [CLEAR_STATE](context) {
      context.commit(CLEAR_STATE);
    }
  },
  mutations: {
    [SET_CURRENT_ROUTE](state, route) {
      state.currentRoute = route;
    },
    [SET_ROUTE_PARAMS](state, payload) {
      let { prefix, params, currentPath } = payload;

      if (!state?.params?.[currentPath]) {
        Vue.set(state.params, currentPath, {});
      }

      Vue.set(state.params[currentPath], prefix, params);
    },
    [SET_ROUTE_PARAMS_BY_KEY](state, payload) {
      let { prefix, value, key, currentPath } = payload;
      let currentRouteParams = state?.params?.[currentPath]?.[prefix] ?? {};
      let paramsToSave = {
        ...currentRouteParams,
        ...{
          [key]: value
        }
      };

      if (!state?.params?.[currentPath]) {
        Vue.set(state.params, currentPath, {});
      }

      Vue.set(state.params[currentPath], prefix, paramsToSave);
    },
    [REMOVE_ROUTE_PARAMS](state, payload) {
      delete state.params[payload.currentPath][payload.prefix];
    },
    [REMOVE_ROUTE_PARAMS_BY_KEY](state, payload) {
      for (const key in state.params[payload.currentPath][payload.prefix]) {
        if (key.startsWith(payload.key))
          delete state.params[payload.currentPath][payload.prefix][key];
      }
    },
    [CLEAR_STATE](state) {
      Object.assign(state, getDefaultState());
    }
  }
};
