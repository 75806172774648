import {
  arrayOperators,
  customRegex,
  fieldDataTypes,
  fieldDefaults,
  locatorValidators,
  numberValidators,
  simpleValidators,
  singleParameterValidators
} from "@/components/Tools/FormHelper/Helper/constants";
import store from "@/core/services/store";
import i18n from "@/core/plugins/vue-i18n";
import { noValueOperators } from "@/components/Tools/FormHelper/Helper/constants";
// Built-in validators
import * as validators from "vuelidate/lib/validators";
import _ from "lodash";
import DataOptions from "@/components/Tools/FormHelper/dataOptions";
import { Toast } from "@/core/plugins/swal";
import { isJson } from "@/components/Tools/helperFunctions";
// Custom validators
validators.alphaSpace = validators.helpers.regex(
  "alphaSpace",
  customRegex.alphaSpace
);
validators.alphaNumSpace = validators.helpers.regex(
  "alphaNumSpace",
  customRegex.alphaNumSpace
);
// Without required validator, the regex helper would return true on empty string
validators.variable = validators.and(
  validators.required,
  validators.helpers.regex("variable", customRegex.variable)
);
validators.json = value => {
  if (!value) {
    return true;
  }
  if (typeof value === "object") {
    if (Array.isArray(value)) {
      return true;
    } else {
      return !Object.keys(value).some(key =>
        ["", null, undefined].includes(key)
      );
    }
  } else if (typeof value === "string") {
    let result;
    try {
      JSON.parse(value);
      result = true;
    } catch (e) {
      result = false;
    }
    return result;
  } else {
    return false;
  }
};

export function stripHtml(string) {
  return string.replace(customRegex.html, "");
}

export function formatDateTime(
  date,
  format = "DD.MM.YYYY HH:mm:ss",
  inputFormat = undefined
) {
  const dayjs = require("dayjs");
  const customParseFormat = require("dayjs/plugin/customParseFormat");
  dayjs.extend(customParseFormat);

  if (!date || !dayjs(date, inputFormat).isValid()) return false;

  return dayjs(date, inputFormat).format(format);
}

export function nestedValue(value, name, onlyPath = false) {
  // Split name by dots
  let nameParts = name.split(".");
  // Loop through name parts
  for (let i = 0; i < nameParts.length; i++) {
    // If only path should be returned and name part is second least
    if (onlyPath && i + 1 >= nameParts.length) {
      break;
    }
    // Update value
    value = value[nameParts[i]];
  }
  return value;
}

/**
 * Cast value
 * text|number|variable|true|false|null
 */
export function typeCast(value) {
  let result = value;
  if (
    typeof value === "string" &&
    ((value.startsWith('"') && value.endsWith('"')) ||
      (value.startsWith("'") && value.endsWith("'")))
  ) {
    result = value.slice(1, -1);
  } else if (value === "true") {
    result = true;
  } else if (value === "false") {
    result = false;
  } else if (value === "null") {
    result = null;
  } else if (!isNaN(value) && value !== "") {
    result = Number(value);
  }
  return result;
}

export function stringify(value) {
  let result = String(value);

  if ([true, false, null].includes(value)) {
    result = String(value);
  } else if (["true", "false", "null"].includes(value)) {
    result = `"${value}"`;
  } else if (
    typeof value === "string" &&
    value.length &&
    !isNaN(Number(value))
  ) {
    result = '"' + value + '"';
  } else if (typeof value === "object") {
    result = JSON.stringify(value);
  }
  return result;
}

export function typeOf(value, exact = false) {
  if (value === undefined) return "";
  let type = typeof value;
  if (type === "string") {
    type = customRegex.variable.test(value) ? "variable" : "text";
  } else if (type === "boolean") {
    type = exact ? String(value) : type;
  } else if (value === null) {
    type = "null";
  } else if (type === "object" && Array.isArray(value)) {
    type = "array";
  }
  return type;
}

// Checks if value type and accepted types of form field match
export function typeCheck(value, fieldType) {
  // Get type of current value
  let type = typeOf(value);
  // Check if type matches one of field's types
  if (
    fieldDataTypes[fieldType]?.includes("any") ||
    fieldDataTypes[fieldType]?.includes(type)
  ) {
    return value;
  }
  // If not, return a default value
  return fieldDefaults[fieldType];
}

/**
 * Param type:
 * - default: Only variables
 * - none: Nothing
 * - text: Text and variables
 * - number: Numbers and variables
 * - all: Text, numbers, variables
 */
export function highlight(
  text,
  enableVariables,
  returnType,
  highlightType = "default"
) {
  if (highlightType === "none") {
    return text;
  }

  switch (highlightType) {
    case "default":
      break;
    case "text":
      text = highlightText(text);
      break;
    case "number":
      text = highlightNumbers(text);
      break;
    case "all":
      if (returnType === "number") {
        text = `<span class="highlight-number">${text}</span>`;
      } else if (["text", "variable"].includes(returnType)) {
        text = `<span class="highlight-text">${text}</span>`;
      } else {
        text = `<span class="highlight-bool-null">${text}</span>`;
      }
      break;
    default:
      break;
  }

  if (enableVariables) {
    text = highlightVariables(text);
  }

  text = `<div class="contenteditable-content">${text}</div>`;
  return text;
}

function highlightVariables(text) {
  let replace = "<span class='highlight-variable'>$1</span>";
  return text.replace(/({{[^({{)(}})]*}})/gm, replace);
}
function highlightText(text) {
  return `<span class="highlight-text">${text}</span>`;
}
function highlightNumbers(text) {
  let replace = "<span class='highlight-number'>$1</span>";
  return text.replace(/(\d+(?:\.\d+)?)/gm, replace);
}

export function saveCursorPosition(containerEl) {
  let start;
  if (window.getSelection && document.createRange) {
    let selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) {
      return;
    }
    let range = selection.getRangeAt(0);
    let preSelectionRange = range.cloneRange();
    preSelectionRange.selectNodeContents(containerEl);
    preSelectionRange.setEnd(range.startContainer, range.startOffset);
    start = preSelectionRange.toString().length;
    return {
      start: start,
      end: start + range.toString().length
    };
  } else if (document.selection) {
    let selectedTextRange = document.selection.createRange();
    let preSelectionTextRange = document.body.createTextRange();
    preSelectionTextRange.moveToElementText(containerEl);
    preSelectionTextRange.setEndPoint("EndToStart", selectedTextRange);
    start = preSelectionTextRange.text.length;
    return {
      start: start,
      end: start + selectedTextRange.text.length
    };
  }
}

export function restoreCursorPosition(containerEl, savedSel) {
  if (!savedSel) {
    return;
  }
  if (window.getSelection && document.createRange) {
    let charIndex = 0,
      range = document.createRange();
    range.setStart(containerEl, 0);
    range.collapse(true);
    let nodeStack = [containerEl],
      node,
      foundStart = false,
      stop = false;
    while (!stop && (node = nodeStack.pop())) {
      if (node.nodeType === 3) {
        let nextCharIndex = charIndex + node.length;
        if (
          !foundStart &&
          savedSel.start >= charIndex &&
          savedSel.start <= nextCharIndex
        ) {
          range.setStart(node, savedSel.start - charIndex);
          foundStart = true;
        }
        if (
          foundStart &&
          savedSel.end >= charIndex &&
          savedSel.end <= nextCharIndex
        ) {
          range.setEnd(node, savedSel.end - charIndex);
          stop = true;
        }
        charIndex = nextCharIndex;
      } else {
        let i = node.childNodes.length;
        while (i--) {
          nodeStack.push(node.childNodes[i]);
        }
      }
    }
    let sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (document.selection) {
    let textRange = document.body.createTextRange();
    textRange.moveToElementText(containerEl);
    textRange.collapse();
    textRange.moveEnd("character", savedSel.end);
    textRange.moveStart("character", savedSel.start);
    textRange.select();
  }
}

export function getValidations(
  validationsOriginal,
  enableVariables = false,
  fieldType
) {
  let config = {},
    validations = _.cloneDeep(validationsOriginal);
  Object.keys(validations).forEach(validator => {
    // Get validation by validator
    let v = getValidator(validator, validations[validator], enableVariables);
    // If no validation rule was determined (e.g. required: false)
    if (!v) {
      return;
    }
    // Set validation
    config[validator] = v;
    // If field type is number but no number validator is set
    // take numeric as fallback
    if (
      fieldType === "number" &&
      !numberValidators.some(i => Object.keys(validations).includes(i))
    ) {
      config.numeric = enableVariables
        ? validators.or(validators.numeric, validators.variable)
        : validators.numeric;
    } else if (
      fieldType === "float" &&
      !numberValidators.some(i => Object.keys(validations).includes(i))
    ) {
      config.numeric = enableVariables
        ? validators.or(
          validators.decimal,
          validators.numeric,
          validators.variable
        )
        : validators.or(validators.decimal, validators.numeric);
    }
  });
  return config;
}

function getValidator(validator, params, enableVariables = false) {
  // Set not as false
  let not = false;
  // If params contains not
  if (typeof params === "object" && params?.not) {
    // Set not to true and delete not from params
    not = true;
    delete params.not;
    // If validator is a single parameter validator
    // mutate params to left param
    if (
      (singleParameterValidators.includes(validator) ||
        locatorValidators.includes(validator)) &&
      Object.keys(params).length === 1
    ) {
      params = Object.values(params)[0];
    }
  }
  // Declare validation
  let validation = undefined;
  if (simpleValidators.includes(validator) && params !== false) {
    // Simple validators (bool)
    if (enableVariables) {
      validation = validators.or(validators[validator], validators.variable);
    } else {
      validation = validators[validator];
    }
  } else if (numberValidators.includes(validator)) {
    // Number validators
    if (enableVariables) {
      validation = validators.or(validators[validator], validators.variable);
    } else {
      validation = validators[validator];
    }
  } else if (
    singleParameterValidators.includes(validator) ||
    locatorValidators.includes(validator)
  ) {
    // Single parameter validators and locators validators
    validation = validators[validator](params);
  } else if (validator === "between") {
    // Between validator
    validation = validators["between"](params.min, params.max);
  } else if (["or", "and"].includes(validator)) {
    // If validator is "or" or "and"
    let andOr = [];
    // Get validation for params
    Object.keys(params).forEach(param => {
      let v = getValidator(param, params[param], enableVariables);
      if (!v) {
        return;
      }
      andOr.push(v);
    });
    validation = validators[validator](...andOr);
  } else {
    return null;
  }
  // If not flag was set
  if (not) {
    // Wrap validation in not validator
    validation = validators.not(validation);
  }
  // Return validation
  return validation;
}

export function customVariablesNormalized(configs) {
  let sets = [];
  configs.forEach(config => {
    // Set item
    let set = { name: config.name, prefix: config.prefix };
    // Get custom variables from store
    let variables = store.getters["variables/customVariablesSet"](config.name);
    if (config.keyValuePairs) {
      // If keyValuePairs is set in config, transform variables to object
      let variablesFormatted = [];
      // Set default item text and value keys
      set.text = "text";
      set.value = "value";
      // Iterate variables
      Object.keys(variables).forEach(key => {
        // Add variable with key as text and value as value
        variablesFormatted.push({
          text: key,
          value: variables[key]
        });
      });
      // Set formatted variables
      set.variables = variablesFormatted;
    } else {
      // Else just take over configuration and set variables
      set.text = config.text ?? "text";
      set.value = config.value ?? "value";
      set.variables = variables;
    }
    // Add to sets
    sets.push(set);
  });
  return sets;
}

export function renderConditions(item, operators, isFirst = true) {
  let text = "";
  if (item.type === "group") {
    let groupText = "";
    item.children.forEach((child, index) => {
      let childText = renderConditions(child, operators, false);
      if (index > 0 && groupText && childText) {
        groupText +=
          item.operator === "and"
            ? " && "
            : item.operator === "or"
            ? " || "
            : "";
      }
      groupText += childText;
    });
    if (groupText.length) {
      text += isFirst ? groupText : `(${groupText})`;
    }
  } else if (
    item.type === "condition" &&
    item.field &&
    item.operator &&
    String(item.value).length
  ) {
    let value = String(item.value);
    if (
      typeof item.value === "string" &&
      !(value.startsWith("{{") && value.endsWith("}}")) &&
      !(value.startsWith('"') && value.endsWith('"')) &&
      !(value.startsWith("'") && value.endsWith("'"))
    ) {
      value = '"' + value + '"';
    } else if (
      arrayOperators.includes(item.operator) &&
      Array.isArray(item.value)
    ) {
      value = "[" + value + "]";
    }

    let operatorLabel =
      operators.find(o => o.name === item.operator)?.label ?? item.operator;
    text += `${item.field} ${operatorLabel}`;
    if (!noValueOperators.includes(item.operator)) {
      text += ` ${value}`;
    }
  }
  return text;
}

export function checkDependencies(field, values, customValues) {
  // If no dependency is set
  if (!field.dependsOn) {
    // Test is automatically true
    return true;
  }
  // Set counter to 0 to check if all dependencies are fulfilled
  let dependencyCounter = 0;
  // Loop through dependencies
  for (const dependency of field.dependsOn) {
    // Get full name split by nesting dots
    let nameSplit = dependency.name.split(".");
    // Set value as all values initially
    let value = values;

    if (dependency?.useCustomValues && customValues) {
      value = customValues;
    }

    // Get nested value
    for (let i = 0; i < nameSplit.length; i++) {
      if (value === undefined) {
        continue;
      }
      value = value[nameSplit[i]];
    }
    // Set fulfilled initially true
    let fulfilled = true;
    // If values is set
    if (dependency.values) {
      if (
        Array.isArray(value) &&
        !value.some(val => dependency.values.includes(val))
      ) {
        // Else if value is array and values match with value
        fulfilled = false;
      } else if (!Array.isArray(value) && !dependency.values.includes(value)) {
        // If values don't include value
        fulfilled = false;
      } else {
        if (field.dependsOnMode === "single") {
          dependencyCounter = field.dependsOn.length;
          break;
        }
      }
    }
    // If notValues is set
    if (dependency.notValues) {
      if (typeof value === "string" && dependency.notValues.includes(value)) {
        // If value is string and notValues includes value
        fulfilled = false;
      } else if (
        Array.isArray(value) &&
        value.some(val => dependency.notValues.includes(val))
      ) {
        // If value is array and notValues match with value
        fulfilled = false;
      }
    }
    // If dependency is still fulfilled
    if (fulfilled) {
      // Increment dependency counter
      dependencyCounter++;
    }
  }
  // Return if dependency counter matches dependencies length
  return dependencyCounter === field.dependsOn.length;
}

// Get text as snippet by given prefix
export function getSnippet(text, prefix, data = {}) {
  if (i18n.te(`${prefix}.${text}`)) {
    // If a snippet with text exists
    text = i18n.t(`${prefix}.${text}`, data);
  } else if (typeof text === "object") {
    // If text is an object
    if (i18n.locale in text) {
      // If text has locale as key
      text = text[i18n.locale];
    } else if ("en" in text) {
      // If text has "en" as key, use it as fallback
      text = text.en;
    } else if ("de" in text) {
      // If text has "de" as key, use it as fallback
      text = text.de;
    }
  }
  // Return text
  return text;
}

export function transformValue(value, targetType = "") {
  switch (targetType) {
    case "text":
    case "variable":
      value = String(value);
      break;
    case "true":
      value = ["true", true].includes(value) ? true : value;
      break;
    case "false":
      value = ["false", false].includes(value) ? false : value;
      break;
    case "null":
      value = ["null", null].includes(value) ? null : value;
      break;
    case "number":
      value = Number(value);
      break;
    case "array":
      value = value.split(",");
      break;
    default:
      break;
  }
  return value;
}

export function getValueType(value) {
  let type = "text";
  if (customRegex.variable.test(value)) {
    type = "variable";
  } else if ([true, false, null].includes(value)) {
    type = String(value);
  } else if (!isNaN(value) && value !== "") {
    type = "number";
  }
  return type;
}

export function checkValueType(value, targetType = "") {
  let valid = false;
  switch (targetType) {
    case "text":
    case "variable":
      valid = typeof value === "string";
      break;
    case "true":
      valid = value === true;
      break;
    case "false":
      valid = value === false;
      break;
    case "null":
      valid = value === null;
      break;
    case "number":
      valid = !isNaN(value) && value !== "";
      break;
    default:
      break;
  }
  return valid;
}

export async function getDataOptions(field, payload) {
  return DataOptions.get(payload)
    .then(response => {
      if (response.error) {
        Toast.fire({
          icon: "error",
          title: response.error
        });

        return;
      }

      if (typeof response === "object") response = Object.values(response);

      if (!Array.isArray(response) || response.length <= 0) {
        Toast.fire({
          icon: "info",
          title: i18n.t("formHelper.dataOptions.notFound")
        });

        field.options = [];

        return;
      }

      const currentOptions = field.options;

      // Gather all unique values from both arrays
      const allValues = Array.from(
        new Set([
          ...currentOptions.map(item => item.value),
          ...response.map(item => item.value)
        ])
      );

      // Merge objects based on the unique values
      const mergedArray = allValues.map(value => {
        const item1 = currentOptions.find(item => item.value === value) || {};
        const item2 = response.find(item => item.value === value) || {};
        return { ...item1, ...item2, value };
      });

      field.loading = false;
      field.options = Object.values(mergedArray);
    })
    .catch(response => {
      field.loading = false;

      const error = isJson(response) ? JSON.parse(response) : response;
      const errorMessage =
        error?.status && parseInt(error.status) === 401
          ? i18n.t("general.authenticationError")
          : error?.message ?? error;

      Toast.fire({
        icon: "error",
        title: errorMessage
      });
    });
}
